import api from "../utils/api";
import { setUserProperties, trackEvent } from "../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";
import { getLocationService } from "./ip.service";

export const createBookmark = async ({ label, postId, source }) => {
    try {
        const response = await api.post('/bookmark/create', { label, postId, source });
        trackEvent(MIXPANEL_EVENTS.BOOKMARK_COLLECTION, {
            "Bookmark collection": label
        })
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const storeBookmark = async (data) => {
    try {
        const response = await api.post('/bookmark/store', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const removeBookmark = async (data) => {
    try {
        const response = await api.post('/bookmark/remove', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const getCollectionsWithPostId = async ({ postId }) => {
    // console.log(postId)
    try {
        const response = await api.get(`/bookmark/get-collections/${postId}`);
        if (response.data) {
            setUserProperties({
                "Number of bookmarks": response?.data?.AllPostData?.totalPostCount,
                "Number of collections": response?.data?.length
            })
        }
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};


export const getCollections = async () => {
    try {
        const response = await api.get(`/bookmark`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const getbookmarkPosts = async (data) => {
    try {
        console.log(data, "inside service");
        const response = await api.post(`/bookmark/get-bookmarks`, data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};