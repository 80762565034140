import { useState, useEffect, useMemo } from "react";
import PlanCard from "../../component/PlanCard";
import SegmentedBtn from "../../component/SegmentedBtn";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchPlans, loadScript, paymentVerified, subscribePlan } from "../../services/payment.service";
import PaymentSuccessModel from "./PaymentSucessModel";
import LoaderGif from "../../asset/gif/loader.gif";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateSubscription } from "../../redux/slice/auth.slice";
import { RAZORPAY_KEY } from "../../utils/config";
import { Link, Navigate } from "react-router-dom";
import moment from "moment";
import { setUserProperties, trackEvent, trackPageView } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import CustomPlan from "../../component/CustomPlan";
import { Xmark } from "iconoir-react";
import FreePlanCard from "../../component/FreePlanCard";
import CouponDialog from "../../component/CouponDialogBox";

const BackgroundSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="400" height="708" viewBox="0 0 400 708" fill="none">
            <path opacity="0.67" d="M122.969 -68.0932L321.308 38.0632C385.329 72.3283 404.486 155.156 362.013 214.052L12.4321 698.81" stroke="url(#paint0_linear_52_5067)" strokeWidth="30" />
            <defs>
                <linearGradient id="paint0_linear_52_5067" x1="590.016" y1="465.409" x2="-87.54" y2="103.457" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0693513" stopColor="#5F45FD" />
                    <stop offset="0.33" stopColor="#FDA1F5" />
                    <stop offset="0.776542" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const trackPlanChange = ({ originalPlan, currentPlan }) => {
    trackEvent(MIXPANEL_EVENTS.PLAN_CHANGED, {
        'Original plan': originalPlan,
        'Current plan': currentPlan
    })
    setUserProperties({
        'Current plan': currentPlan
    })
}
const PaymentPending = () => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;

    // const tenure = ['Monthly', 'Yearly'];
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [response, setResponse] = useState(null);
    const [showCoupon, setShowCoupon] = useState(false)
    const [openCancelSubscription, setOpenCancelSubscription] = useState(false);
    const [validCoupon, setValidCoupon] = useState(false);
    const [plans, setPlans] = useState([])
    // const [currentPlan, setCurrentPlan] = useState(null);
    const [couponName, setCouponName] = useState('')
    const [offerId, setOfferId] = useState({})
    const [updatedMonthlyPlan, setUpdatedMonthlyPlan] = useState([]);
    const [updatedYearlyPlan, setUpdatedYearlyPlan] = useState([]);


    useEffect(() => {
        trackPageView(MIXPANEL_EVENTS.PAYMENT_VIEWED);
    }, [])

    const { data, isLoading } = useQuery({
        queryKey: ['plans'],
        queryFn: () => fetchPlans(),
        staleTime: Infinity,
    });

    // Extract monthly and yearly plans from fetched data
    const monthlyPlan = data?.data?.plans.filter((item) => item.billingPeriod === 'monthly')?.sort((a, b) => a.amount - b.amount) || [];
    const yearlyPlan = data?.data?.plans.filter((item) => item.billingPeriod === 'yearly')?.sort((a, b) => a.amount - b.amount) || [];


    // useEffect(() => {
    //     if (yearlyPlan.length > 0) {
    //         setActiveIndex(1);
    //     }
    // }, [data]);

    const handleChange = (index) => {
        trackEvent(MIXPANEL_EVENTS.PLAN_TOGGLED, {
            toggledTo: index === 0 ? 'yearly' : 'monthly'
        })
        // if (index === 0 && monthlyPlan.length > 0) {
        //     setCurrentPlan(monthlyPlan[0]);
        // } else if (yearlyPlan.length > 0) {
        //     setCurrentPlan(yearlyPlan[0]);
        // }
        setActiveIndex(index);
    };

    const { mutate, isPending } = useMutation({
        mutationFn: subscribePlan,
        onSuccess: (data) => {
            if (!data.data?.subscriptionId) {
                toast.success(data.message);
                return;
            }

            const options = {
                key: RAZORPAY_KEY,  // Replace with Razorpay Key ID
                subscription_id: data.data.subscriptionId,
                name: 'SuperPen',
                description: 'Subscription Payment',
                handler: async (response) => {
                    try {
                        const result = await paymentVerified(response);
                        setResponse(result.data)
                        trackPlanChange({
                            originalPlan: subscription?.planName,
                            currentPlan: result?.data?.subscription?.planName
                        })
                        dispatch(updateSubscription(result.data))
                        setOpenSuccess(true);
                    } catch (error) {
                        console.log(error);
                        toast.error('Payment was successful, but there was an error processing your request on the server. Please try again later.');
                    }
                },
                modal: {
                    ondismiss: () => {
                        console.log('Payment Model Closed');
                    },
                },
                theme: {
                    color: '#7145FD',
                },
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
        },
        onError: (data) => {
            toast.error(data.message || 'Server Error');
        }
    });

    const handlePayment = (planId) => {
        if (planId) {
            mutate({
                planId,
                name: offerId?.name,
                paymentMethod: offerId?.paymentMethod
            });
        }
    };

    const handleRemoveCoupon = () => {
        setValidCoupon(false)
        setPlans([])
        setOfferId({})
    }

    useEffect(() => {
        if (validCoupon) {
            // Function to update plans with coupon data
            const updatePlansWithCoupon = (plans, coupon) => {
                return plans.map(plan => {
                    // Find the coupon plan matching the current plan's planId
                    const couponPlan = coupon?.plans?.find(couponPlan => couponPlan?.planId === plan._id);
                    if (couponPlan) {
                        // If a matching coupon plan is found, update the plan with coupon data
                        return {
                            ...plan,
                            primaryText: couponPlan.primaryText,
                            secondaryText: couponPlan.secondaryText,
                            amountAfterDiscount: couponPlan.amountAfterDiscount,
                        };
                    }
                    // If no match is found, return the original plan
                    return plan;
                });
            };

            // Update monthly and yearly plans
            const updatedMonthly = updatePlansWithCoupon(monthlyPlan, offerId);
            const updatedYearly = updatePlansWithCoupon(yearlyPlan, offerId);

            // Update state
            setUpdatedMonthlyPlan(updatedMonthly);
            setUpdatedYearlyPlan(updatedYearly);

            // Set active index based on the type of the coupon
            setActiveIndex(1)
        }
    }, [validCoupon]);

    const currentPlans = useMemo(() => {
        if (validCoupon) {
            // Choose updated plans based on activeIndex and offerId type
            return activeIndex ? updatedMonthlyPlan : updatedYearlyPlan;
        }
        // Fallback to default plans if no coupon is applied
        return activeIndex ? monthlyPlan : yearlyPlan;
    }, [validCoupon, activeIndex, monthlyPlan, yearlyPlan]);

    // if (subscription && subscription.endDate) {
    //     // const today = moment(); // Get today's date
    //     // const endDate = moment(subscription.endDate);

    //     // const daysLeft = endDate.diff(today, 'days');

    //     // if (daysLeft >= 0) {
    //         return <Navigate to='/' />;
    //     // }
    // }

    const currencySymbol = monthlyPlan[0]?.currency === 'INR' ? '₹' : '$';
    const currentAmount = response?.subscription?.amountPaid;
    const currentPlan = response?.subscription?.planName
    return (
        <>
            {openSuccess && (
                <PaymentSuccessModel
                    amount={`${currentPlan} plan`}
                // onClose={() => {
                //     setOpenSuccess(false);
                // }}
                />
            )}

            {
                showCoupon && (
                    <CouponDialog onClose={() => { setShowCoupon(false) }} setValidCoupon={setValidCoupon} setPlans={setPlans} setCouponName={setCouponName} currency={monthlyPlan[0]?.currency} setOfferId={setOfferId} />
                )
            }

            <div className="min-h-screen relative bg-star-light4 pt-10 pb-10 ">
                <div className="absolute top-0 left-0 z-0">
                    <BackgroundSVG />
                </div>
                <Link to={'/'} className="absolute top-[20px] right-[20px] small cursor-pointer icon-outline-btn z-10">
                    <Xmark />
                </Link>
                <div className="container mx-auto  relative z-10">
                    <div >
                        <div className="mt-4">
                            <p className="text-headingThree md:text-headingTwo text-center">Pricing</p>
                        </div>
                        <div className="flex  mt-8 items-baseline justify-between">
                            <div className="flex justify-center items-center mt-4 mb-20 ">
                                <SegmentedBtn list={['Yearly (Save 50%)', 'Monthly']} activeIndex={activeIndex} onChange={handleChange} />
                            </div>
                            {
                                validCoupon ? <>
                                    <div className="">
                                        <div className=" flex justify-center items-center text-bodyOne text-star-light" onClick={() => { setShowCoupon(true) }}>
                                            <div className="mr-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                    <g clip-path="url(#clip0_815_4063)">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0.9375C4.5472 0.9375 0.9375 4.5472 0.9375 9C0.9375 13.4528 4.5472 17.0625 9 17.0625C13.4528 17.0625 17.0625 13.4528 17.0625 9C17.0625 4.5472 13.4528 0.9375 9 0.9375ZM5.64783 8.97725C5.42816 8.75758 5.07201 8.75758 4.85234 8.97725C4.63267 9.19692 4.63267 9.55308 4.85234 9.77275L7.10234 12.0227C7.32201 12.2424 7.67816 12.2424 7.89783 12.0227L13.1478 6.77275C13.3675 6.55308 13.3675 6.19692 13.1478 5.97725C12.9282 5.75758 12.572 5.75758 12.3523 5.97725L7.50008 10.8295L5.64783 8.97725Z" fill="#A489F3" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_815_4063">
                                                            <rect width="18" height="18" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            {couponName}
                                            <b className="text-bodyOneBold ml-1">
                                                Code applied successfully
                                            </b>
                                        </div>
                                        <div className="flex justify-end">
                                            <button className="text-btn-coupon" onClick={handleRemoveCoupon}>
                                                Remove coupon code
                                            </button>
                                        </div>
                                    </div>

                                </> : <>
                                    <div className=" flex justify-center items-center text-bodyOne text-star-default cursor-pointer " onClick={() => { setShowCoupon(true) }}>
                                        <svg className="text-star-default" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="#7145FD" color="#7145FD" stroke-linecap="round">
                                            <path d="M11.4126 10.7314C11.869 10.7314 12.239 11.1015 12.239 11.5579C12.239 12.0143 11.869 12.3843 11.4126 12.3843C10.9561 12.3843 10.5861 12.0143 10.5861 11.5579C10.5861 11.1015 10.9561 10.7314 11.4126 10.7314ZM12.5908 11.5579C12.5908 10.9072 12.0633 10.3797 11.4126 10.3797C10.7619 10.3797 10.2344 10.9072 10.2344 11.5579C10.2344 12.2086 10.7619 12.7361 11.4126 12.7361C12.0633 12.7361 12.5908 12.2086 12.5908 11.5579Z" fill="white" stroke="white" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M6.40073 5.72008C6.85716 5.72008 7.22718 6.0901 7.22718 6.54654C7.22718 7.00298 6.85716 7.373 6.40073 7.373C5.94429 7.373 5.57427 7.00298 5.57427 6.54654C5.57427 6.0901 5.94429 5.72008 6.40073 5.72008ZM7.57893 6.54654C7.57893 5.89584 7.05143 5.36833 6.40073 5.36833C5.75002 5.36833 5.22252 5.89584 5.22252 6.54654C5.22252 7.19724 5.75002 7.72475 6.40073 7.72475C7.05143 7.72475 7.57893 7.19724 7.57893 6.54654Z" fill="white" stroke="white" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.5393 5.41985C12.4706 5.35116 12.3592 5.35116 12.2905 5.41985L5.27422 12.4362C5.20553 12.5048 5.20553 12.6162 5.27422 12.6849C5.3429 12.7536 5.45426 12.7536 5.52294 12.6849L12.5393 5.66857C12.6079 5.59989 12.6079 5.48853 12.5393 5.41985Z" fill="white" stroke="white" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        Do you have a
                                        <div className="text-bodyOneBold ml-1">
                                            Coupon Code?
                                        </div>
                                    </div>

                                </>

                            }

                        </div>
                        <div className="flex flex-wrap md:flex-nowrap justify-center mt-6   gap-5">
                            <FreePlanCard tenure={activeIndex ? 'month' : 'yearly'} />
                            {
                                currentPlans.map((item, index) => (
                                    <PlanCard key={index} index={index} {...item} isActive={false} handlePayment={handlePayment} isPending={isPending} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PaymentPending;
