import { useQuery } from '@tanstack/react-query';
import { DiceThree } from "iconoir-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoaderGif from "../../asset/gif/loader.gif";
import FreeTrialBg from '../../asset/image/freeTrialBg.png';
import Crown from '../../asset/svg/Crown';
import ToggleBtn from '../../component/custom-form/ToggleBtn';
import FreePlanLimitDialog from '../../component/FreePlanLimitDialog';
import MasonaryLayout from "../../component/MasonaryLayout";
import TemplateCard from "../../component/TemplateCard.js";
import useScrolledDown from "../../hooks/useScrolledDown";
import MainLayout from "../../layout/main";
import { fetchTemplate, fetchTemplateCategory } from "../../services/post.service";
import { nearestRoundOff } from '../../utils/helper';
import { trackEvent } from '../../utils/mixpanel';
import { MIXPANEL_EVENTS } from '../../utils/mixpanel/eventType';
import GradientLock from '../../asset/svg/GradientLock';
import TemplateBg from '../../asset/image/template2.png';


const trackFilterEvent = (eventName, name, value) => {
    trackEvent(MIXPANEL_EVENTS.FILTER_CLICKED, {
        "Filter type": name
    })
    trackEvent(eventName, {
        [`${name} Filter`]: value,
    });
}

function getIndexFromSearchUsage(searchUsage, totalObjects) {
    return searchUsage % totalObjects;
}

const Template = () => {
    const authState = useSelector((state) => state.auth);
    const isFreeTrial = authState.subscription.isFreeTrial;
    const dispatch = useDispatch();

    let { scrolledDown: sticky, isScrollingDown } = useScrolledDown(40);
    const [text, setText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [likeFilter, setLikeFilter] = useState({});
    const [contentToneFilter, setContentToneFilter] = useState({});
    const [contentTimeFilter, setContentTimeFilter] = useState({});
    const [contentLengthFilter, setContentLengthFilter] = useState({
        label: 'Short',
        value: {
            min: 0,
            max: 75 * 5
        }
    });
    const [isUnstuck, setIsUnstuck] = useState(false);
    const [toggleIsOn, setToggleIsOn] = useState(false);
    const [openLimitDialog, setOpenLimitDialog] = useState(false);
    const [childToggles, setChildToggles] = useState([]);
    const [category, setCategory] = useState('all')
    const [selectedCategory, setSelectedCategory] = useState('all');
    const query = {
        category: category,
    };


    const { data, isFetching: isLoading, refetch } = useQuery({
        queryKey: ['linkedin-posts', query],
        queryFn: async ({ queryKey }) => {
            // const response = await fetchLinkedInPosts(queryKey[1]);
            const response = await fetchTemplate(queryKey[1]);
            // Initialize childToggles dynamically
            const initialToggles = response?.template?.reduce((acc, child) => {
                acc[child._id] = false; // Default toggle state
                return acc;
            }, {});
            setChildToggles(initialToggles);
            return response;
        },
        staleTime: Infinity,
        retry: false
    });
    const { data: templateType, isLoading: loadTemplate } = useQuery({
        queryKey: ['template-id'],
        queryFn: async () => {
            const response = await fetchTemplateCategory();
            return response
        },
        staleTime: Infinity,
        retry: false

    });

    sticky = isUnstuck ? false : sticky;

    useEffect(() => {
        setIsUnstuck(false);
    }, [isScrollingDown])

    const handleUnStick = () => {
        setIsUnstuck(true);
    }

    useEffect(() => {
        refetch()
    }, [category])

    const metaData = {
        search: searchText,
        postFrom: searchText ? 'Search' : 'Recommendation',
        postTone: contentToneFilter?.value
    }

    const { subscription } = authState;
    const totalPost = (data?.totalPost - 6);

    const toggleAllChildren = (newState) => {


        setChildToggles(() =>
            Object.keys(childToggles).reduce((acc, key) => {
                acc[key] = newState; // Reset each child to the new state
                return acc;
            }, {})
        );
    };

    const handleToggle = () => {
        trackEvent(MIXPANEL_EVENTS.DEFAULT_TEMPLATE,{
            "Switched to": toggleIsOn ? 'Template':'Example'
        })
        if (toggleIsOn) {
            toggleAllChildren(false); // Reset all children to `false`
            setToggleIsOn(false); // Update parent toggle
        } else {
            toggleAllChildren(true); // Reset all children to `true`
            setToggleIsOn(true); // Update parent toggle
        }

    }

    // Toggle an individual child
    const toggleChild = (id) => {
        setChildToggles((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const handleTabClick = (isLocked, id) => {
        if (isLocked) {
            setOpenLimitDialog(true);
        } else {
            setCategory(id);
            setSelectedCategory(id);
            setToggleIsOn(false);
        }
    }
    return (
        <MainLayout>
            {
                openLimitDialog && (
                    <FreePlanLimitDialog title={'Upgrade to view all templates'} onClose={() => { setOpenLimitDialog(false) }} />
                )
            }

            <div className='flex  gap-4 realtive min-h-[91vh]' style={{
                marginTop: isFreeTrial ? 13 : 0
            }}>

                <div className='w-[280px]' />
                <div className='w-[230px] z-20 bg-star-light3 rounded-2xl h-full pb-3 px-3 py-4 pr-0 fixed '>
                    <p className='w-58 text-bodyThree text-gray-5 mt-16 mb-2 p-[12px]'>
                        Template Categories
                    </p>

                    <p className={`w-58 text-bodyOne relative p-[12px] cursor-pointer ${selectedCategory === 'all'
                        ? 'font-bold text-star-default bg-star-light4 rounded-l-lg'
                        : 'text-star-light hover:font-bold hover:text-star-default hover:bg-star-light4 hover:rounded-l-lg'
                        }`} onClick={() => { setCategory('all'); setSelectedCategory('all'); setToggleIsOn(false); }}>
                        All

                        {
                            selectedCategory === 'all' && (

                                <div className='absolute -top-[11px] right-0 h-[148%]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" className='h-full' viewBox="0 0 16 71" fill="none">
                                        <path d="M15.7303 0.888672C15.7471 1.43802 15.7467 1.96361 15.7303 2.46645V13.4774L4.97008 13.4872C3.5517 13.5515 2.22468 13.4952 1.16366 13.4907L0.864258 13.4909C0.961479 13.4903 1.06133 13.4902 1.16366 13.4907L4.97008 13.4872C9.69292 13.2729 15.4287 11.7207 15.7303 2.46645V0.888672Z" fill="#F5F2FF" />
                                        <path d="M0.864258 57.3757C5.50862 57.4093 15.7303 57.3898 15.7303 70.5182V57.3898L0.864258 57.3757Z" fill="#F5F2FF" />
                                        <rect x="9.79395" y="13.3591" width="5.92487" height="44.6177" fill="#F5F2FF" />
                                    </svg>
                                </div>
                            )
                        }
                    </p>

                    {!loadTemplate && (
                        <div className='flex  flex-col gap-1 mt-1'>
                            {
                                templateType?.templateType?.map((category, index) => (

                                    <div key={category._id}  >
                                        <p
                                            className={`w-59 tooltip-wrapper relative flex justify-between text-bodyOne p-[12px] cursor-pointer ${category._id === selectedCategory
                                                ? 'font-bold text-star-default bg-star-light4 rounded-l-lg'
                                                : 'text-star-light hover: hover:text-star-default hover:bg-star-light4 hover:rounded-l-lg'
                                                }`}
                                            onClick={() => {
                                                handleTabClick(index > 4 && isFreeTrial, category._id);
                                                trackEvent(MIXPANEL_EVENTS.TEMPLATE_CATEGORY, {
                                                    'Category selected': category.templateName
                                                });
                                            }}
                                        >
                                            {category.templateName}
                                            {
                                                index > 4 && isFreeTrial &&
                                                (
                                                    <>
                                                        <GradientLock />
                                                        <div className="tooltip absolute  left-[208px] bottom-[-2px]  hidden min-w-max bg-white px-4 py-2" style={{
                                                            boxShadow: "0px 14px 32px 0px rgba(113, 69, 253, 0.11)",
                                                            borderRadius: '0px 8px 8px 8px'
                                                        }}>
                                                            <div className="flex items-center gap-1">
                                                                <Crown />
                                                                <p className="text-bodyThreeBold  text-transparent bg-clip-text bg-heading-gradient-3 ">Upgrade to unlock
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                )

                                            }
                                            {
                                                category._id === selectedCategory && (

                                                    <div className='absolute -top-[11px] right-0 h-[148%]'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" className='h-full' viewBox="0 0 16 71" fill="none">
                                                            <path d="M15.7303 0.888672C15.7471 1.43802 15.7467 1.96361 15.7303 2.46645V13.4774L4.97008 13.4872C3.5517 13.5515 2.22468 13.4952 1.16366 13.4907L0.864258 13.4909C0.961479 13.4903 1.06133 13.4902 1.16366 13.4907L4.97008 13.4872C9.69292 13.2729 15.4287 11.7207 15.7303 2.46645V0.888672Z" fill="#F5F2FF" />
                                                            <path d="M0.864258 57.3757C5.50862 57.4093 15.7303 57.3898 15.7303 70.5182V57.3898L0.864258 57.3757Z" fill="#F5F2FF" />
                                                            <rect x="9.79395" y="13.3591" width="5.92487" height="44.6177" fill="#F5F2FF" />
                                                        </svg>
                                                    </div>
                                                )
                                            }


                                        </p>

                                    </div>
                                ))}
                        </div>
                    )}

                </div>

                <div className="rounded-2xl  flex flex-col relative bg-star-light3 pt-5 pb-2 px-2 py-2 w-full">
                    <div className="flex items-center justify-center gap-2 mt-14">
                        {/* <div className="rounded-2xl bg-star-light w-[52px] h-[5px]" /> */}
                        <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                        <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                        <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                    </div>
                    <div className='mt-9'>
                        <p className=" flex items-center justify-center text-subHeadingTwoBold bg-clip-text text-transparent bg-hover-gradient-1">
                            Select a template
                        </p>
                        <p className="flex items-center justify-center  text-bodyOne  text-gray-6">
                            Top 1% LinkedIn Creators use these viral templates
                        </p>
                    </div>
                    <div className="p-4 pt-[28px]">
                        <div className='flex justify-end mb-3 p-1'>
                            <div className='p-2 rounded-lg bg-gray-moon flex gap-2 mx-3' onClick={handleToggle}>
                                <p className='text-bodyThree text-gray-5'>View Examples</p>
                                <ToggleBtn isOn={toggleIsOn} />
                            </div>

                        </div>

                        {
                            isLoading && (
                                <div className="flex flex-col h-[70vh] w-full justify-center items-center">
                                    <img src={LoaderGif} width={200} alt="loading" />
                                    <p className="text-bodyThreeBold text-star-light">Hang tight, inspiration is on the way!</p>
                                </div>
                            )
                        }
                        {
                            !isLoading && (
                                <MasonaryLayout viralTemplate={true} >
                                    {data?.template?.map((postItem, index) => (
                                        <div key={postItem._id} className="flex-1">
                                            <TemplateCard
                                                {...postItem}
                                                postType={contentToneFilter?.value}
                                                metaData={metaData}
                                                toggleChild={() => {
                                                    console.log(postItem)
                                                    toggleChild(postItem?._id);
                                                    if (!childToggles[postItem?._id]) {
                                                        trackEvent(MIXPANEL_EVENTS.TEMPLATE_FLIPPED, {
                                                            "Template ID": postItem?._id,
                                                            "Template Name":postItem.name
                                                        })
                                                    }
                                                }} // Pass toggle function
                                                isToggled={childToggles[postItem?._id]} // Pass toggle state
                                                index={index + 1}
                                                refetch={() => refetch()}
                                            />
                                        </div>
                                    ))}
                                </MasonaryLayout>

                            )
                        }
                        {
                            !isFreeTrial && !isLoading && data?.data?.length > 0 && (
                                <div className='flex items-center justify-center my-4'>
                                    <button className='outlined-btn small border-none' onClick={() => refetch()}>
                                        <DiceThree />
                                        Renew inspiration feed
                                    </button>
                                </div>
                            )
                        }

                    </div>
                    {
                        isFreeTrial && !isLoading && (
                            <div className='p-4 mt-auto'>
                                <div className='rounded-xl relative w-full pt-[47px] pb-[89px] '>
                                    <img src={TemplateBg} alt='background-img' className='w-full top-0 h-full absolute z-0' />
                                    <div className='flex flex-col items-center justify-center relative z-10'>
                                        <p className='text-headingThree mt-7 mb-7 text-white text-center'> Upgrade to view all templates</p>
                                        {/* <p className='text-subHeadingThree mt-3 mb-5 text-white text-center'>Need more inspiration?
                                        </p> */}
                                        <Link to={'/payment-pending'} className='filled-btn white small'><Crown /> <p >Upgrade Plan Now</p> </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </MainLayout>
    )
}

export default Template;