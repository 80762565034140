import axios from "axios";
import api from "../utils/api";
import { ONESIGNAL_KEY } from "../utils/config";
import { getLocationService } from "./ip.service";
import { trackEvent } from "../utils/mixpanel";

const trackError = (error, api) => {
    const errorMessage =
        error?.response?.data?.message || // Message from the server
        error.message ||                 // General error message
        'Server Error';                  // Fallback error message

    // Track error event with detailed info
    trackEvent('signup_error_log', {
        error: JSON.stringify({
            message: errorMessage,
            status: error?.response?.status || 'Unknown',
            data: error?.response?.data || {},
        }),
        api
    });
}

export const loginService = async ({ email, password }) => {
    try {
        const response = await api.post('/auth/login', { email, password });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const loginTokenService = async ({ token }) => {
    try {
        const response = await api.post('/auth/token-login', { token });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
export const registerService = async ({ name, email, password }) => {
    try {
        // throw new Error("Worked")
        const locationDetail = await getLocationService();
        const response = await api.post('/auth/register', { name, email, password, locationDetail });
        const obj = {
            email: response.data?.user?.email,
            firstName: response.data?.user?.name?.split(' ')[0],
            userId: response.data?.user?._id
        }
        createOneSignalUser(obj);
        return response.data;
    } catch (error) {
        trackError(error, 'register')
        throw new Error(error?.message || error.response?.data?.message || 'Server Error');
    }
};

export const sendOTPService = async ({ email, name }) => {
    try {
        const response = await api.post('/auth/send-otp/email', { email, name });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const verifyOTP = async ({ email, otp }) => {
    try {
        const response = await api.post('/auth/verify-otp', { email, otp });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const googleLogin = async ({ accessToken }) => {
    try {
        const locationDetail = await getLocationService();
        const response = await api.post('/auth/google', { accessToken, locationDetail });
        if (response?.data?.method !== 'login') {
            const obj = {
                email: response.data?.user?.email,
                firstName: response.data?.user?.name?.split(' ')[0],
                userId: response.data?.user?._id
            }
            createOneSignalUser(obj);
        }
        return response.data;
    } catch (error) {
        trackError(error, 'google')
        throw new Error(error?.message || error.response?.data?.message || 'Server Error');
    }
};

export const sendPasswordResetLink = async ({ email }) => {
    try {
        const response = await api.post('/auth/send-password-reset-link', { email });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
export const resetPassword = async ({ userId, token, password }) => {
    try {
        const response = await api.post('/auth/password-reset', { userId, token, password });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};


export const createOneSignalUser = async ({ email, firstName, userId }) => {
    try {
        const oneSignalToken = ONESIGNAL_KEY
        const response = await axios.post(`https://api.onesignal.com/apps/${oneSignalToken}/users`, {
            subscriptions: [
                {
                    type: "Email",
                    token: email,
                    enabled: true,
                },
            ],
            properties: {
                tags: {
                    first_name: firstName,
                },
            },
            identity: {
                external_id: userId,
            },
        }, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        });
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
