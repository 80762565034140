const UserStar = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M2 20V19C2 15.134 5.13401 12 9 12V12" stroke="#7145FD" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.6164 12.8062C16.012 12.2101 16.8874 12.2101 17.283 12.8062L18.3245 14.3759C18.4573 14.5762 18.6579 14.7219 18.8894 14.7864L20.7041 15.2919C21.3934 15.4838 21.6639 16.3164 21.2191 16.8768L20.0481 18.3524C19.8987 18.5407 19.8221 18.7764 19.8323 19.0166L19.9124 20.8986C19.9428 21.6135 19.2345 22.128 18.5641 21.8782L16.7989 21.2205C16.5737 21.1365 16.3258 21.1365 16.1005 21.2205L14.3353 21.8782C13.6649 22.128 12.9566 21.6135 12.9871 20.8986L13.0671 19.0166C13.0773 18.7764 13.0007 18.5407 12.8513 18.3524L11.6803 16.8768C11.2355 16.3164 11.506 15.4838 12.1953 15.2919L14.01 14.7864C14.2415 14.7219 14.4421 14.5762 14.575 14.3759L15.6164 12.8062Z" fill="none" stroke="#7145FD" strokeWidth="1.6" />
            <path d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z" stroke="#7145FD" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export default UserStar;
