import { createSlice } from '@reduxjs/toolkit';

const favCreatorSlice = createSlice({
    name: 'favCreator',
    initialState: {
        creatorIds: [],
        selectedCreatorsIds: [] // Array to store creator IDs
    },
    reducers: {
        addCreator: (state, action) => {
            if (!state.creatorIds.includes(action.payload)) {
                state.creatorIds = [...state.creatorIds, action.payload];
            }
        },
        removeCreator: (state, action) => {
            state.creatorIds = state.creatorIds.filter(id => id !== action.payload);
        },
        toggleCreator: (state, action) => {
            if (state.creatorIds.includes(action.payload)) {
                // Remove if already in the array
                state.creatorIds = state.creatorIds.filter(id => id !== action.payload);
            } else {
                // Add if not present
                state.creatorIds = [...state.creatorIds, action.payload];
            }
        },
        setAllCreator: (state, action) => {
            state.creatorIds = action.payload;
        },
        resetCreators: (state) => {
            state.creatorIds = [];
        },
        selectCreator: (state, action) => {
            if (!state.selectedCreatorsIds.includes(action.payload)) {
                state.selectedCreatorsIds = [...state.selectedCreatorsIds, action.payload];
            }
        },
        unSelectCreator: (state, action) => {
            state.selectedCreatorsIds = state.selectedCreatorsIds.filter(id => id !== action.payload);
        },
        selectAllCreator: (state, action) => {
            state.selectedCreatorsIds = action.payload;
        },
        resetSelectedCreators: (state) => {
            state.selectedCreatorsIds = [];
        },
    },
});

export const {
    addCreator,
    removeCreator,
    toggleCreator,
    resetCreators,
    setAllCreator,
    selectCreator,
    unSelectCreator,
    selectAllCreator,
    resetSelectedCreators
} = favCreatorSlice.actions;

export default favCreatorSlice.reducer;
