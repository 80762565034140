import React, { useState } from 'react'
import { Xmark, CreditCard, UPi,ArrowLeft } from 'iconoir-react'
import { useQuery,useMutation} from '@tanstack/react-query';
import { validCoupon} from "../services/payment.service";


function CouponDialog({ onClose,setValidCoupon,setPlans,setCouponName,currency,setOfferId}) {
    const [selectedPayment, setSelectedPayment] = useState('card')
    const [couponCode, setCouponCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)


    const { mutate: mututeValidCoupon, isPending: isPendingCoupon } = useMutation({
        mutationFn: validCoupon,
        onSuccess: (data) => {
            if(data.error){
                setError(`${data.error} please try another coupon`)
                setIsLoading(false)
                return 
            }

            setIsLoading(true)
            setValidCoupon(true)
            setPlans(data.plans)
            setCouponName(data.name)
            setOfferId(data)
            // Handle successful discount application (e.g., show success message, update UI)
            onClose() 
         
        },
        onError: (error) => {
            setIsLoading(false)
            setError('Error applying discount. Please try again.')
            console.log("error",error)
        }
    });



    const handleApplyDiscount = async () => {
        setError(null)
        if(couponCode === ''){
           setError("coupon code is required ") 
           return ;
        }
        setIsLoading(true)
        mututeValidCoupon({name:couponCode,paymentMethod:selectedPayment})
    }

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="relative p-8 w-[529px] bg-white rounded-[25px]">
                {/* Header */}
                <div className="flex justify-between items-center w-full">
                    <div className="text-bodyOne text-gray-5">
                        Enter your <span className="font-bold">Coupon Code!</span>
                    </div>
                    <button
                        className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                        onClick={onClose}
                    >
                        <Xmark className="w-5 h-5" />
                    </button>
                </div>

                {/* Coupon Input */}
                <div className="mt-6">
                    <input
                        type="text"
                        placeholder="Enter coupon code"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        className="w-full px-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                        required
                    />  {/* Error Message */}
                    
                    {error ? 
                      <div className="mt-1 text-red-500 text-sm">{error}</div> :<div></div>
                  }
                </div>
                {/* Payment Method */}

                {
                    currency === "INR" && 
                    <div className="mt-6">
                    <div className="text-bodyOne text-gray-4 mb-3">
                        Select your payment method
                    </div>
                    <div className="flex gap-6">
                    <label className="flex items-center gap-2 cursor-pointer">
                            <input
                                type="radio"
                                name="payment"
                                value="card"
                                checked={selectedPayment === 'card'}
                                onChange={(e) => setSelectedPayment(e.target.value)}
                                className="w-4 h-4 accent-star-default border-gray-300 focus:text-star-default"
                            />
                            <span className="text-bodyOne flex justify-center text-center gap-2 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4259 4.87305C21.9447 4.87305 23.1759 6.10426 23.1759 7.62305V17.623C23.1759 19.1419 21.9447 20.373 20.4259 20.373H4.42593C2.90712 20.373 1.67593 19.1419 1.67593 17.623V10.373H18.4259C18.8401 10.373 19.1759 10.0373 19.1759 9.62305C19.1759 9.20883 18.8401 8.87305 18.4259 8.87305H1.67593V7.62305C1.67593 6.10427 2.90711 4.87305 4.42593 4.87305H20.4259Z" fill="url(#paint0_linear_815_3870)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_815_3870" x1="23.1759" y1="20.373" x2="-0.219648" y2="8.65892" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#5F38DB" />
                                            <stop offset="1" stop-color="#A489F3" />
                                        </linearGradient>
                                    </defs>
                                </svg> Credit Card
                            </span>
                        </label>
                        <label className="flex items-center gap-2 cursor-pointer">
                            <input
                                type="radio"
                                name="payment"
                                value="upi"
                                checked={selectedPayment === 'upi'}
                                onChange={(e) => setSelectedPayment(e.target.value)}
                                className="w-4 h-4 accent-star-default border-gray-300 focus:text-star-default"
                            />
                            <span className="text-bodyOne flex justify-center text-center gap-2 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" viewBox="0 0 18 25" fill="none">
                                    <path d="M11.3914 0.622925L17.4262 12.6245L4.74011 24.6229L11.3914 0.622925Z" fill="#27803B" />
                                    <path d="M7.16116 0.622925L13.1915 12.6245L0.500244 24.6229L7.16116 0.622925Z" fill="#E9661C" />
                                </svg>
                                UPI</span>
                        </label>
                    </div>
                </div>

                }

                {/* Footer */}
                <div className="flex justify-between mt-8">

                    <button className="text-btn small" type="button" onClick={onClose}>
                                    <ArrowLeft /> Back
                                </button>
                    
                    <button
                        onClick={handleApplyDiscount}
                        disabled={isLoading}
                        className={`filled-btn small text-base ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {isLoading ? 'Applying...' : 'Apply Discount'}
                    </button>
                
                </div>
            </div>
        </div>
    )
}

export default CouponDialog

