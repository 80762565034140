import { useEffect, useState } from "react";
import CommentScreen from "../RecreatePost/CommentScreen";
import WritingScreen from "./WritingScreen.js";
import WritingScreenStep2 from "./WritingScreenStep2.js";
import PreviewScreen from "./PreviewScreen";
import { ContentState, EditorState } from "draft-js";
import { generatePost ,generateTemplate} from "../../services/genai.service";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateAiCreditUsage, updateSubscription } from "../../redux/slice/auth.slice";
import PERMISSIONS, { hasPermission } from "../../utils/permissions";
import DialogBox from "../../component/DialogBox";
import PermissionLockImg from '../../asset/svg/PermissionLock.svg';
import { Xmark } from "iconoir-react";
import ChoiceScreen from "../RecreatePost/ChoiceScreen";

const RecreateTemplate = (props) => {
    const dispatch = useDispatch();
    const [currentScreen, setCurrenScreen] = useState(0);
    const [selectedComment, setSelectedComment] = useState([]);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
   const [template,setTemplate] = useState(true)
   const [media,setMedia] = useState({})
   const [uploadedFiles, setUploadedFiles] = useState([]);
   const [image, setIsImage] = useState(false);
    const [isAiGenerated, setIsAiGenerated] = useState(false);

    const [openPermissionDialog, setOpenPermissionDialog] = useState(false);

    const highlightText = (text, highlightColor) => {
        const customSymbolRegex = /\^([^\^]+)\^/g;  // No need to escape ^
    
        const parts = text?.split(customSymbolRegex);
        
        return parts?.map((part, index) => {
            // Odd indices will contain content between `^`, indicating the highlighted text
            if (index % 2 !== 0) {
                return part;  // Simply return the part without the ^ symbols
            }
            return part; // Return non-highlighted parts as they are
        }).join('');
    };

    const { mutate, isPending: postGenerating } = useMutation({
        mutationFn: generateTemplate,
        onSuccess: (data) => {
            const contentState = ContentState.createFromText(data?.data?.content || "");
            setEditorState(EditorState.createWithContent(contentState));
            dispatch(updateSubscription({ subscriptionLimit: data?.data?.subscriptionLimit }));
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong !!!')
        }
    });

    const handleCheckClicked = (comment) => {
        setSelectedComment((prev) => {
            const isSelected = prev.some(selected => selected._id === comment._id);

            if (isSelected) {
                return prev.filter(selected => selected._id !== comment._id);
            } else {
                return [...prev, comment];
            }
        });
    };

    const handleNext = () => {
        setCurrenScreen((prev) => prev + 1);
    }

    const handleBack = () => {
        
        if(currentScreen === 0){
            props.refetch()
        }
        setCurrenScreen((prev) => prev - 1);
    }

    
    const handleGenerateNewPost = (templateType) => {
        if (!hasPermission(PERMISSIONS.AI_GENERATE)) {
            setOpenPermissionDialog(true);
            return;
        }
        
        setIsAiGenerated(true);
        const { content } = props;
        let contentWithoutspecialChar = highlightText(content)
        let exampleWithoutSpecialChar = highlightText(props?.contentExample)
        let comments = '';
        selectedComment.forEach((comment, index) => {
            comments += `${index + 1}: ${comment.commentText}\n`; // Adding index properly
        });

        mutate({ content:contentWithoutspecialChar, editorText:editorState.getCurrentContent().getPlainText(), tag: props?.postType,templateType:templateType,templateExample:exampleWithoutSpecialChar
            ,templateCategory:props?.isFunny }); // Pass content and selected comments to mutation
    };

    const handleWriteMannual = () => {
        setIsAiGenerated(false);
        setEditorState(EditorState.createEmpty())
    }
    const steps = [
        // <CommentScreen {...props}
        //     handleGenerateNewPost={handleGenerateNewPost}
        //     handleWriteMannual={handleWriteMannual}
        //     selectedComment={selectedComment}
        //     handleCheckClicked={handleCheckClicked}
        //     handleNext={handleNext}
        // />,
        <WritingScreen {...props}
            handleGenerateNewPost={handleGenerateNewPost}
            editorState={editorState}
            postGenerating={postGenerating}
            isAiGenerated={isAiGenerated}
            setEditorState={setEditorState}
            handleWriteMannual={handleWriteMannual}
            selectedComment={selectedComment}
            setMedia={setMedia}
            handleNext={handleNext}
            handleBack={handleBack}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            image={image}
            setIsImage={setIsImage}
        />,
        <WritingScreenStep2 {...props}
        handleGenerateNewPost={handleGenerateNewPost}
        editorState={editorState}
        postGenerating={postGenerating}
        isAiGenerated={isAiGenerated}
        setEditorState={setEditorState}
        handleWriteMannual={handleWriteMannual}
        selectedComment={selectedComment}
        handleNext={handleNext}
        handleBack={handleBack}
        setTemplate={setTemplate}
        setMedia={setMedia}
        template={template}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        image={image}
        setIsImage={setIsImage}
    />,
    
        <PreviewScreen {...props}
            editorState={editorState}
            selectedComment={selectedComment}
            media={media}
            handleNext={handleNext}
            handleBack={handleBack}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            image={image}
            setIsImage={setIsImage}
        />
    ];
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);
    return (
        <>
            {openPermissionDialog && <GenaiPermissionDialog onClose={() => { setOpenPermissionDialog(false) }} />}
            {steps[currentScreen]}
        </>
    )

}

export default RecreateTemplate;

const FreeTrialExpireBg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="126" height="186" viewBox="0 0 126 186" fill="none">
            <path d="M104.192 119.984C119.504 119.601 126.077 100.375 114.203 90.6986L63.6378 49.4881C62.3935 48.474 61.6543 46.9669 61.6142 45.3622L59.9834 -19.849C59.6004 -35.1614 40.3749 -41.7338 30.6982 -29.8605L-10.5122 20.705C-11.5263 21.9493 -13.0334 22.6885 -14.6381 22.7286L-79.8494 24.3594C-95.1618 24.7424 -101.734 43.9679 -89.8608 53.6446L-39.2954 94.855C-38.051 95.8691 -37.3119 97.3762 -37.2717 98.9809L-35.6409 164.192C-35.258 179.505 -16.0324 186.077 -6.35577 174.204L34.8547 123.638C35.8688 122.394 37.3759 121.655 38.9806 121.615L104.192 119.984Z" stroke="url(#paint0_linear_238_9621)" strokeWidth="11" />
            <defs>
                <linearGradient id="paint0_linear_238_9621" x1="32.0346" y1="174.714" x2="-52.0975" y2="-32.3145" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8773FF" />
                    <stop offset="0.380119" stopColor="#FFC0FA" />
                    <stop offset="1" stopColor="#FBDEBC" />
                </linearGradient>
            </defs>
        </svg>
    )
}
const GenaiPermissionDialog = ({ onClose }) => {
    return (
        <DialogBox>
            <div className="relative p-8 w-[680px] bg-white rounded-[25px] overflow-hidden">
                <div className="top-0 left-0 absolute">
                    <FreeTrialExpireBg />
                </div>
                <div className="flex justify-end">
                    <button className="icon-outline-btn small border-none" onClick={onClose}>
                        <Xmark />
                    </button>
                </div>
                <div className="text-center flex flex-col items-center justify-center">
                    <div>
                        <img src={PermissionLockImg} alt="expire" />
                    </div>
                    <p className="text-bodyOneBold">Uh oh! AI generation is not available in this plan.
                    </p>
                    <p className="mt-2 text-bodyOne text-gray-5">To access this feature, please contact us hello@superpen.io</p>
                    <button className="filled-btn mt-6" onClick={onClose}>Okay</button>
                </div>
            </div>
        </DialogBox>
    )
}