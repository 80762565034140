import api from "../utils/api";
import { setUserProperties, trackEvent } from "../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";

export const fetchFavCreator = async (prop) => {
    try {
        const response = await api.get('/favourite-creator/fetch-creator', {
            params: {
                status: prop.status
            }
        });
        if (response.data) {
            setUserProperties({
                "Number of favourite creators currently added": response.data.total
            })
        }
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
export const fetchSuggestedCreator = async (prop) => {
    try {
        const response = await api.get('/favourite-creator/suggested-creator', {
            params: {
                persona: prop.persona
            }
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
export const fetchFavCreatorPost = async (body) => {
    try {
        const response = await api.post('/favourite-creator/fetch-post', body);

        // if (prop.search) {
        //     trackEvent(MIXPANEL_EVENTS.SEARCH_KEYWORD, {
        //         "Keyword searched": prop.search,
        //         "Number of results": response?.data?.totalCount
        //     })
        // }

        // if (response?.data?.data?.length === 0) {
        //     trackEvent(MIXPANEL_EVENTS.NO_POSTS_FOUND, { "Keyword searched": prop.search });
        // }
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const addFavCreator = async (body) => {
    try {
        const response = await api.post('/favourite-creator/add-creator', body);

        if (response.data?.limitExceed) {
            trackEvent(MIXPANEL_EVENTS.CREATOR_LIMIT)
        }
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
export const removeFavCreator = async (params) => {
    try {
        const response = await api.delete('/favourite-creator/remove-creator', {
            params
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const addFavCreatorByURL = async (body) => {
    try {
        const response = await api.post('/favourite-creator/add-creator-by-url', body);
        if (response.data?.limitExceed) {
            trackEvent(MIXPANEL_EVENTS.CREATOR_LIMIT)
        }
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};