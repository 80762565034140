import moment from "moment/moment";
import BadgeCheckFilled from "../asset/svg/BadgeCheckFilledIcon";
import SoloLogo from "../asset/svg/SoloLogo";
import { useSelector } from "react-redux";
import { trackEvent } from "../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";


const PlanCard = (props) => {
    const authState = useSelector((state) => state.auth);

    const subscription = authState.subscription;
    const isFreeTrial = authState.subscription.isFreeTrial;


    const {
        isActive,
        className,
        name,
        billingPeriod,
        amount,
        amountBeforeDiscount,
        features,
        _id,
        currency,
        description,
        index = 0,
        handlePayment,
        isPending,
        primaryText,
        secondaryText,
        amountAfterDiscount

    } = props;

    const currencySymbol = currency === 'INR' ? '₹' : '$';
    const tenure = billingPeriod === 'monthly' ? '/month' : '/month for 12 months';

    const theme = [
        {
            color: '#7145FD',
            background: '#FFF',
        },
        {
            color: '#2789EC',
            background: '#F0FAFF',
        }, 
        {
            color: '#7145FD',
            background: '#F7F6FF',
        },
    ]

    const { color, background } = theme[index];
    const isBest = isActive ? false : index === 0;
    const actualAmount = billingPeriod === 'yearly' ? (amount / 12)?.toLocaleString() : amount?.toLocaleString();
    const isCancelled = subscription?.status === 'cancelled';
    const showPayButton = (subscription.isFreeTrial || (moment(subscription.endDate).isBefore(moment()) && isCancelled)) && handlePayment;
    return (
        <div className={`relative border flex-1 flex flex-col  rounded-[20px] px-[26px] py-9 ${className} max-w-[385px]`} style={{ borderColor: color, background: background }}>
            {isActive && <div className="absolute -top-[15px] left-0 right-0  m-auto rounded-lg text-white w-[fit-content] bg-star-default px-3 py-1">
                Current Plan 👇
            </div>}
            {
                isBest && <div className="absolute -top-[50px] left-0 right-0 w-full -z-10 rounded-[20px] text-white text-center bg-star-default  pt-4 py-10">
                    <p className="text-bodyOneBold md:text-headingFour">Best Value 🚀
                    </p>
                </div>
            }
            {/* <div className="absolute -top-[16px] right-0 w-[214px] h-[207px]">
                <SoloLogo className={'w-full h-full'} />
            </div> */}
            <p className="text-headingThree " style={{ color: color }}>{name}</p>
            <p className="text-bodyTwo text-nowrap text-gray-6">{description}</p>
            {
                secondaryText && primaryText ?
                    <div className={`rounded-[12px] p-3 mt-3`} style={{ background: color }}>

                        <div className="flex  items-center gap-2">
                            <p className="text-subHeadingTwo font-semibold text-gray-moon">{currencySymbol}{amountAfterDiscount}</p>
                            <p className="text-gray-moon text-bodyTwoBold">{primaryText}</p>
                        </div>
                        <div className="absolute left-[87px] top-[107px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="10" viewBox="0 0 21 10" fill="none">
                                <path d="M19.7997 -7.68359C15.4724 -2.09975 6.60814 -2.58834 1.30334 8.99764" stroke="#FDBC11" stroke-width="1.70835" />
                            </svg>
                        </div>
                        <div className="absolute right-[141px] top-[116px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                <path d="M3.75501 0.0148327L3.29305 4.00423L0.137638 1.75935L3.75501 0.0148327Z" fill="#D2B7FF" />
                            </svg>
                        </div>

                        <div className="absolute right-[95px] top-[109px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="7" viewBox="0 0 35 7" fill="none">
                                <path d="M1.54806 6.41251C1.78325 2.36051 3.96237 -6.10817 10.7973 -7.56698C19.3411 -9.3905 19.6713 -1.53336 16.4499 1.14561C13.2285 3.82458 4.13169 -2.27577 11.8697 -15.2499C19.6076 -28.2241 32.5517 -23.6461 33.5027 -17.0602C34.3668 -11.0752 28.6968 -6.60753 23.0079 -11.9374C19.3677 -15.3479 19.4086 -27.5548 31.1257 -33.5264" stroke="#FCE940" stroke-width="2.57143" />
                            </svg>
                        </div>

                        <div className="absolute right-[61px] top-[107px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30" fill="none">
                                <path d="M0.734619 -1.90173C1.40947 0.0991541 3.15 4.44993 4.71331 5.84591C6.66745 7.5909 8.20336 14.8498 4.71331 16.0364C1.22325 17.2229 2.34001 13.8727 4.01515 13.3841C5.69028 12.8955 11.4137 9.47543 14.2056 20.7128C14.7639 23.2953 18.882 20.4336 20.9759 23.0161C22.651 25.0821 23.1163 28.0648 23.1396 29.2979" stroke="#BFFFF4" stroke-width="0.976202" />
                            </svg>
                        </div>

                        <div className="absolute right-[134px] top-[139px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5" fill="none">
                                <path d="M0.583524 0.63066L3.93992 2.83595L0.516573 4.64616L0.583524 0.63066Z" fill="#A5FF82" />
                            </svg>
                        </div>

                        <div className="absolute right-[50px] top-[140px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
                                <path d="M11.865 1.70959C10.0512 6.07554 5.3755 15.3792 1.18286 17.6661" stroke="#3BA55A" stroke-width="3.90481" />
                            </svg>
                        </div>

                        <div className="absolute right-[26px] top-[150px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="35" viewBox="0 0 17 35" fill="none">
                                <path d="M1.57455 41.9241C1.80974 37.8721 3.98886 29.4034 10.8238 27.9446C19.3676 26.1211 19.6977 33.9782 16.4764 36.6572C13.255 39.3362 4.15818 33.2358 11.8962 20.2617C19.6341 7.28751 32.5782 11.8655 33.5291 18.4514C34.3933 24.4364 28.7233 28.9041 23.0344 23.5742C19.3942 20.1637 19.4351 7.95675 31.1522 1.98518" stroke="#FCE940" stroke-width="2.57143" />
                            </svg>
                        </div>


                        <div className="absolute right-[111px] top-[173px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
                                <path d="M19.7694 17.207C15.4421 11.6232 6.57787 12.1118 1.27307 0.525801" stroke="#FDBC11" stroke-width="1.70835" />
                            </svg>
                        </div>

                        <div className="flex items-center gap-2">
                            
                            <span className="text-bodyTwo text-gray-moon">{secondaryText} </span>
                        </div>
                    </div> :
                    <div>
                        <p className="text-subHeadingTwo font-semibold text-gray-5 mt-8"> <del>{currencySymbol}{amountBeforeDiscount?.toLocaleString()}</del></p>
                        <div className="flex items-center gap-2">
                            <p className="text-headingTwo text-space-default">{currencySymbol}{actualAmount}  </p>
                            <span className="text-bodyTwo text-gray-5">{tenure}</span>
                        </div>
                    </div>

            }

            {/* <p className="text-bodyOne text-gray-5">+ Taxes</p> */}
            <div className="mt-5">
                <p className="text-bodyTwoBold " style={{ color: color }}>Get started with</p>
                <div className="flex mt-4 flex-col gap-2">
                    {
                        features?.map((item, index) => (
                            <div key={index} className="flex items-start gap-2">
                                <div className="mt-[1px]">
                                    <BadgeCheckFilled color={color} />
                                </div>
                                <p className="text-bodyOne text-gray-6">{item.title}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                isActive && subscription.endDate && (
                    <div className="flex flex-col mt-8 items-center justify-center">
                        <p className="text-bodyThree text-gray-5">Subscription  {isCancelled ? 'ends on' : 'Renewal Date'}</p>
                        <p className="text-bodyOneBold">{moment(subscription.endDate).format("DD MMM'YY")}</p>
                    </div>
                )
            }
            <p className="text-bodyTwo text-gray-6 pt-5 text-center mt-auto mb-4"> Cancel anytime, don't get charged next month
            </p>
            {
                showPayButton && (
                    <div className="flex flex-col  items-center justify-center">
                        <button disabled={isPending} className={`filled-btn w-[100%] ${color === '#2789EC' ? 'blue' : '#FF90F6'} ` } onClick={() => {
                            handlePayment(_id)
                            trackEvent(MIXPANEL_EVENTS.PAYMENT_INITIATED, {
                                'Timeline': billingPeriod,
                                'Plan chosed': name
                            })
                        }}>Start now</button>

                    </div>
                )
            }
            {
                !isCancelled && !isFreeTrial && !isActive && (
                    <div className="flex items-center justify-center ">
                        <p className="text-button text-center" style={{ color: color }}><span className="font-semibold">Contact us</span>  <br /> <span className="font-bold">hello@superpen.io</span></p>
                    </div>
                )
            }
        </div>
    )
}
export default PlanCard;