import { useMutation } from "@tanstack/react-query";
import { ArrowLeft, ArrowUpRight } from "iconoir-react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AvatarIcon from "../../asset/svg/AvatarIcon";
import LinkedinIcon from "../../asset/svg/LinkedinIcon";
import XIcon from "../../asset/svg/XIcon";
import { addCreator } from "../../redux/slice/favCreator.slice";
import { addFavCreator } from "../../services/favCreator.service";
import { convertToK } from "../../utils/helper";
import DialogBox from "../DialogBox";
import { Link } from "react-router-dom";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const LineSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="99" height="129" viewBox="0 0 99 129" fill="none">
            <path d="M-212.081 128.291C-215.003 129.12 -218.044 127.424 -218.873 124.501C-219.703 121.579 -218.006 118.538 -215.084 117.709L-212.081 128.291ZM-110.675 -64.2489L38.7426 -64.2282L38.7411 -53.2282L-110.676 -53.2488L-110.675 -64.2489ZM54.9767 52.512L-212.081 128.291L-215.084 117.709L51.974 41.9298L54.9767 52.512ZM38.7426 -64.2282C107.798 -64.2186 121.409 33.6615 54.9767 52.512L51.974 41.9298C106.125 26.5642 95.0296 -53.2204 38.7411 -53.2282L38.7426 -64.2282Z" fill="url(#paint0_linear_153_10027)" />
            <defs>
                <linearGradient id="paint0_linear_153_10027" x1="-488.16" y1="262.98" x2="264.298" y2="-209.694" gradientUnits="userSpaceOnUse">
                    <stop offset="0.288815" stopColor="#FBDEBC" />
                    <stop offset="0.427773" stopColor="#FFC0FA" />
                    <stop offset="0.567652" stopColor="#C5BBFF" />
                    <stop offset="0.75671" stopColor="#FD6483" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const LimitExceedCreatorDialog = (props) => {
    const authState = useSelector((state) => state.authState);
    const isFreeTrial = authState?.subscription?.isFreeTrial;
    const {
        onClose,
        userId,
        source,
        accountId
    } = props;

    const dispatch = useDispatch();
    // const queryClient = useQueryClient();

    return (
        <DialogBox>
            <div className="rounded-[25px] relative bg-gray-moon p-8">
                <div className="absolute top-0 left-0">
                    <LineSVG />
                </div>
                <div className="flex items-center gap-4">
                    <div className="text-center">
                        <p className="bg-heading-gradient-3 bg-clip-text text-transparent text-headingThree">You can’t add this profile <br /> as your favourite creator.</p>
                        <p className="text-subHeadingThree text-gray-6 mt-1">To get top performing posts of {(userId.fullName || userId.username || '').split(' ')[0]}, <br />upgrade to a paid plan  </p>
                    </div>
                    <div className="rounded-xl overflow-hidden w-[216px] relative border border-star-light2 flex items-center justify-center flex-col p-4">
                        <div className="w-full absolute top-0 h-[80px]" style={{ background: '#E2F1FF' }} >
                            {/* {
                                userId.coverPhoto ? <img src={userId.coverPhoto} className="h-full object-cover w-full" alt="cover-photo" /> : <></>
                            } */}
                        </div>
                        <div className="relative">
                            <AvatarIcon src={userId.profilePhoto} width="108" height="108" />
                            <div className="absolute bottom-0 right-0">
                                {props.source === 'linkedin' ? <LinkedinIcon width={26} height={26} /> : <XIcon width={26} height={26} />}
                            </div>
                        </div>
                        <a href={userId.url} target="_blank" className="flex items-center justify-center mt-3 text-bodyOneBold hover:text-star-default text-space-default">
                            <p className="text-ellipsis overflow-hidden text-nowrap w-[80%]">
                                {userId.fullName || userId.username}
                            </p>
                            <ArrowUpRight width={20} />
                        </a>
                        <p className="text-bodyTwo w-full text-ellipsis overflow-hidden text-nowrap text-gray-6">
                            {userId.headLine}
                        </p>
                        {
                            userId.followerCount > 0 && <p className="text-bodyOne mt-1 text-star-light">{convertToK(userId.followerCount)} followers </p>
                        }
                    </div>
                </div>
                <div className="flex justify-between mt-8">
                    <button className="text-btn" onClick={onClose}>
                        <ArrowLeft />
                        <p>Back</p>
                    </button>
                    {
                        isFreeTrial && (
                            <Link to={'/payment-pending'} className="filled-btn w-[217px]" onClick={() => {
                                trackEvent(MIXPANEL_EVENTS.UPGRADE_CLICKED, {
                                    'Upgrade clicked from': 'Favourite creator'
                                })
                            }} >
                                Subscribe now
                            </Link>
                        )
                    }

                </div>
            </div>
        </DialogBox>
    )
}
export default LimitExceedCreatorDialog;