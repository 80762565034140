import { ArrowLeft, Mail } from "iconoir-react";
import DialogBox from "../../component/DialogBox";
import { useSelector } from "react-redux";
import AvatarIcon from "../../asset/svg/AvatarIcon";
import LinkedinIcon from "../../asset/svg/LinkedinIcon";
import XIcon from "../../asset/svg/XIcon";

const LineSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="160" height="68" viewBox="0 0 160 68" fill="none">
            <path d="M154.439 -133.928L103.909 11.9466C91.0554 49.0542 51.7533 69.9636 13.7972 59.8876L-133.564 20.7687" stroke="url(#paint0_linear_153_5306)" stroke-width="11" />
            <defs>
                <linearGradient id="paint0_linear_153_5306" x1="-84.5244" y1="19.9182" x2="128.103" y2="98.7758" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8773FF" />
                    <stop offset="0.505" stopColor="#FFC0FA" />
                    <stop offset="1" stopColor="#FBDEBC" />
                </linearGradient>
            </defs>
        </svg>
    )
}
function calculateTotalTime(totalTasks, concurrencyLimit, taskTime) {
    if (totalTasks === 0) { return taskTime; }
    const timeSlots = Math.ceil(totalTasks / concurrencyLimit);
    const totalTime = timeSlots * taskTime;
    return totalTime;
}
const SuccessAddCreatorPopup = (props) => {
    const authState = useSelector((state) => state.auth);

    const {
        onClose,
        url,
        queueSize,
        onCloseAll
    } = props;


    let timeText = `${calculateTotalTime(queueSize, 4, 10)}`
    return (
        <DialogBox>
            <div className="rounded-[25px] relative bg-gray-moon py-10 px-12">
                <div className="absolute top-0 left-0">
                    <LineSVG />
                </div>
                <div className="flex items-center gap-6">
                    <div className="text-center">
                        <p className="bg-heading-gradient-3 bg-clip-text text-transparent text-headingThree">Creator addition in progress...</p>
                        <p className="text-bodyOne mt-1 text-gray-6 "> Estimated time ~{timeText} minutes</p>
                        <p className="text-subHeadingThree mt-6 text-gray-6 "> We will notify you at </p>
                        <div className="text-subHeadingThree justify-center text-gray-6 gap-1 mt-2.5 flex items-center">
                            <Mail />
                            <p><b>{authState.email}</b> when done</p>
                        </div>
                    </div>
                    <div className="rounded-xl overflow-hidden w-[216px] relative border border-star-light2 flex items-center justify-center flex-col p-4">
                        <div className="w-full absolute top-0 h-[80px]" style={{ background: '#E2F1FF' }} />
                        <div className="mt-3 relative">
                            <AvatarIcon width="108" height="108" />
                        </div>
                        <div className="flex flex-col mt-3 items-center justify-center">
                            <p className="text-bodyOneBold text-space-default">Your Fav Creator</p>
                            {
                                url.includes('linkedin') ?
                                    <LinkedinIcon width={16} height={16} />
                                    : <XIcon />
                            }

                        </div>

                    </div>
                </div>
                <div className="flex justify-between mt-8">
                    <button className="text-btn" onClick={onCloseAll}>
                        <ArrowLeft />
                        <p>Favourite Creators</p>
                    </button>
                    <button className="filled-btn w-[217px]" onClick={onClose}>
                        Add more creators
                    </button>
                </div>
            </div>
        </DialogBox>
    )
}
export default SuccessAddCreatorPopup;