import React, { useEffect, useRef } from 'react';

const Tooltip = ({ children }) => {
    const tooltipWrapperRef = useRef(null);

    useEffect(() => {
        const handleMouseEnter = (event) => {
            const tooltip = event.target.querySelector('.tooltip');
            if (!tooltip) return;

            const tooltipRect = tooltip?.getBoundingClientRect();
            if(!tooltipRect) return;
            const bodyWidth = document.body.clientWidth;

            // Check if the tooltip overflows the right edge
            if (tooltipRect.right + 200 > bodyWidth) {
                tooltip.style.left = 'auto'; // Remove default left alignment
                tooltip.style.right = '0';  // Align to the right
            } else {
                tooltip.style.left = '0';  // Reset to default
                tooltip.style.right = 'auto';
            }
        };

        const tooltipWrapper = tooltipWrapperRef.current;
        if (tooltipWrapper) {
            tooltipWrapper.addEventListener('mouseenter', handleMouseEnter);
        }

        // Cleanup event listener on unmount
        return () => {
            if (tooltipWrapper) {
                tooltipWrapper.removeEventListener('mouseenter', handleMouseEnter);
            }
        };
    }, []);

    return (
        <div ref={tooltipWrapperRef} className="tooltip-wrapper">
            {children}
        </div>
    );
};

export default Tooltip;
