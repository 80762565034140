const CircleStar = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#7145FD" />
            <path d="M8.15166 7.96117L9.55865 5.12706C9.73927 4.76323 10.2611 4.76323 10.4417 5.12706L11.8487 7.96117L14.9951 8.41845C15.3988 8.47712 15.5597 8.97052 15.2675 9.25356L12.9911 11.4581L13.5283 14.5724C13.5973 14.9724 13.1751 15.2774 12.8139 15.0885L10.0002 13.6173L7.18646 15.0885C6.82524 15.2774 6.403 14.9724 6.47199 14.5724L7.00922 11.4581L4.73286 9.25356C4.44059 8.97052 4.60149 8.47712 5.0052 8.41845L8.15166 7.96117Z" fill="#F7F6FF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.95347 5.27236C9.94287 5.27861 9.93176 5.28857 9.92226 5.3077L8.51527 8.14182C8.45596 8.26128 8.3418 8.34402 8.20982 8.3632L5.06336 8.82047C4.99017 8.83111 4.96765 8.91566 5.01522 8.96173L7.29157 11.1662C7.38806 11.2597 7.43212 11.3948 7.40929 11.5271L6.87207 14.6415C6.86848 14.6623 6.8716 14.6762 6.87615 14.6867C6.88138 14.6987 6.89108 14.7115 6.9055 14.722C6.91993 14.7324 6.93569 14.738 6.94997 14.7393C6.96261 14.7405 6.97818 14.7388 6.99795 14.7285L9.81166 13.2573C9.92958 13.1956 10.0702 13.1956 10.1881 13.2573L13.0018 14.7285C13.0216 14.7388 13.0372 14.7405 13.0498 14.7393C13.0641 14.738 13.0799 14.7324 13.0943 14.722C13.1087 14.7115 13.1184 14.6987 13.1237 14.6867C13.1282 14.6762 13.1313 14.6623 13.1277 14.6415L12.5905 11.5271C12.5677 11.3948 12.6117 11.2597 12.7082 11.1662L14.9846 8.96173C15.0321 8.91567 15.0096 8.83111 14.9364 8.82047L11.79 8.3632C11.658 8.34402 11.5438 8.26128 11.4845 8.14182L10.0775 5.3077C10.068 5.28857 10.0569 5.27861 10.0463 5.27236C10.0343 5.26525 10.0182 5.26044 9.9999 5.26044C9.98163 5.26044 9.96554 5.26525 9.95347 5.27236ZM9.1945 4.94641C9.52446 4.28178 10.4753 4.28178 10.8053 4.94641L12.1176 7.58977L15.0533 8.01642C15.7875 8.12312 16.0868 9.02537 15.5498 9.54539L13.4277 11.6005L13.9284 14.5034C14.0555 15.2402 13.28 15.7908 12.6254 15.4485L9.9999 14.0757L7.37444 15.4485C6.71979 15.7908 5.94429 15.2402 6.07139 14.5034L6.57212 11.6005L4.44998 9.54539C3.913 9.02537 4.21228 8.12312 4.94651 8.01642L7.88221 7.58977L9.1945 4.94641Z" fill="#F7F6FF" />
        </svg>

    )
}
export default CircleStar;