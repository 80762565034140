const LinkedinIcon = ({ width = 24, height = 24 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="g1905">
                <path id="path1901" fillRule="evenodd" clipRule="evenodd" d="M22.2617 0H1.80914C0.830857 0 0 0.773714 0 1.72686V22.272C0 23.2263 0.545143 24 1.52343 24H21.976C22.9554 24 24 23.2263 24 22.272V1.72686C24 0.773714 23.2411 0 22.2617 0Z" fill="white" />
                <path id="path1903" fillRule="evenodd" clipRule="evenodd" d="M22.2617 0H1.80914C0.830857 0 0 0.773714 0 1.72686V22.272C0 23.2263 0.545143 24 1.52343 24H21.976C22.9554 24 24 23.2263 24 22.272V1.72686C24 0.773714 23.2411 0 22.2617 0ZM9.14286 9.14286H12.3737V10.7897H12.4091C12.9017 9.90171 14.3566 9 16.1554 9C19.608 9 20.5714 10.8331 20.5714 14.2286V20.5714H17.1429V14.8537C17.1429 13.3337 16.536 12 15.1166 12C13.3931 12 12.5714 13.1669 12.5714 15.0823V20.5714H9.14286V9.14286ZM3.42857 20.5714H6.85714V9.14286H3.42857V20.5714ZM7.28571 5.14286C7.28584 5.42422 7.23054 5.70285 7.12298 5.96284C7.01541 6.22283 6.85768 6.45908 6.6588 6.65811C6.45991 6.85714 6.22378 7.01503 5.96387 7.12279C5.70396 7.23054 5.42536 7.28604 5.144 7.28612C4.86264 7.28619 4.58401 7.23084 4.32404 7.12323C4.06407 7.01561 3.82785 6.85784 3.62886 6.65892C3.42987 6.46 3.27202 6.22383 3.16432 5.9639C3.05661 5.70397 3.00116 5.42536 3.00114 5.144C3.0011 4.5759 3.2267 4.03104 3.62834 3.62927C4.02998 3.22749 4.57476 3.0017 5.14286 3.00155C5.71096 3.00139 6.25585 3.2269 6.65771 3.62846C7.05956 4.03002 7.28546 4.57476 7.28571 5.14286Z" fill="#0A66C2" />
            </g>
        </svg>

    )
}

export default LinkedinIcon;