import { NavArrowDown } from "iconoir-react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Crown from "../../asset/svg/Crown";
import FreePlanLimitDialog from "../FreePlanLimitDialog";
import GradientLock from "../../asset/svg/GradientLock";

const SelectBox = ({ value, icon, label, options, onRemoveFilter, onChange ,removeBtnText='Remove Filter'}) => {
    const dropdownRef = useRef(null);
    const authState = useSelector((state) => state.auth);
    const [showModel, setShowModel] = useState(false);
    const [openLimitDialog, setOpenLimitDialog] = useState(false);

    const isFreeTrial = authState.subscription.isFreeTrial;
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowModel(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const areAllLocksTrue = options.every(option => option.lock) && isFreeTrial;

    return (
        <>
            {
                openLimitDialog && (
                    <FreePlanLimitDialog title={'This premium feature is available in a paid plan'} onClose={() => { setOpenLimitDialog(false) }} />
                )
            }
            <div className="relative" ref={dropdownRef}>
                <div
                    className="rounded-lg  cursor-pointer bg-gray-moon flex gap-2 items-center px-2 py-1 hover:bg-star-light4 transition-colors duration-300"
                    onClick={() => { setShowModel(!showModel) }}
                >
                    <div>
                        {icon}
                    </div>
                    <p className="text-bodyThree whitespace-nowrap text-gray-5">
                        {label}
                    </p>
                    {
                        value.label && <>
                            <p className="text-bodyThree text-gray-5">|</p>
                            <p className="text-bodyThreeBold whitespace-nowrap text-ellipsis overflow-hidden max-w-[55px] text-gray-6">{value.label}</p>
                        </>
                    }
                    {areAllLocksTrue ? <GradientLock /> : <NavArrowDown width={15} height={15} color="#8B8699" />}
                </div>

                {showModel && (
                    <div className="show absolute right-0 z-20 top-[35px] min-w-[155px]  p-2 rounded-lg bg-white border-[0.5px] border-gray-4 shadow-purple" >
                        <p className="text-bodyThree text-gray-4 mb-4">{label}</p>
                        <div className="flex flex-col gap-2">
                            {options.map((option, index) => (

                                option.lock && isFreeTrial ? (
                                    <div key={index} className="tooltip-wrapper" onClick={() => { setOpenLimitDialog(true); }}>
                                        <div className={`px-2 py-[2px] h-[28px] text-bodyTwo  rounded-lg text-nowrap  cursor-pointer border border-transparent text-gray-4 hover:bg-star-light4 hover:text-star-light hover:border hover:border-star-light flex justify-between items-center`}>
                                            {option.label}

                                            <GradientLock />
                                        </div>

                                        <div className="tooltip absolute z-10 left-[130px] hidden min-w-max bg-white px-4 py-2" style={{
                                            boxShadow: "0px 14px 32px 0px rgba(113, 69, 253, 0.11)",
                                            borderRadius: '0px 8px 8px 8px'
                                        }}>
                                            <div className="flex items-center gap-1">
                                                <Crown />
                                                <p className="text-bodyThreeBold  text-transparent bg-clip-text bg-heading-gradient-3 ">Upgrade to unlock
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            onChange(option);
                                            setShowModel(false);  // Close dropdown after selecting an option
                                        }}
                                        className={`px-2 py-[2px] text-bodyTwo rounded-lg text-nowrap text-gray-5 cursor-pointer hover:bg-star-light4 hover:text-star-light
                                         ${option.value === value.value ? 'bg-star-light4 text-star-light' : ''}`}
                                    >
                                        {option.label}
                                    </div>
                                )
                            ))}
                        </div>
                        {onRemoveFilter && (
                            <button className="text-btn small mt-4" onClick={() => { setShowModel(false); onRemoveFilter() }}>
                                {removeBtnText}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default SelectBox;
