import { useEffect, useRef, useState } from "react";
import GradientSearchIcon from "../../asset/svg/GradientSearchIcon";
import { ArrowRight } from "iconoir-react";

const NormalSearch = ({ icon, value, onChange, onPressEnter, onClear, ...other }) => {
    const ref = useRef();
    const [hasFocus, setFocus] = useState(false);

    useEffect(() => {
        if (document.hasFocus() && ref.current.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);

    const handleSelectKeyword = (selectedValue) => {
        onPressEnter(selectedValue);
    };

    return (
        <>
            <div className="h-[52px] relative">
                <div className="absolute z-20 w-full bg-white  rounded-lg"
                    style={{
                        border: hasFocus ? '1px solid transparent' : '1px solid #E0DDED',
                        backgroundImage: hasFocus ? 'linear-gradient(#FFF, #FFF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)' : undefined,
                        backgroundOrigin: 'border-box',
                        backgroundClip: 'padding-box, border-box',
                        outline: 'none'
                    }}>
                    <div className="flex h-full items-center px-3">
                        <div className="cursor-pointer" >
                            {icon}
                        </div>

                        <input className="h-full py-3 w-full bg-transparent border-none outline-none pl-4"
                            ref={ref}
                            value={value}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            onChange={(event) => { onChange(event.target.value); }}
                            // onKeyDown={(event) => {
                            //     if (event.key === 'Enter') {
                            //         handleSelectKeyword(event.target.value);
                            //     }
                            // }}
                            {...other}
                        />
                        {
                            value && (
                                <div
                                    className="rounded-full h-[26px] w-[26px] p-1 flex items-center justify-center bg-hover-gradient-1 text-white cursor-pointer"
                                    onClick={() => { handleSelectKeyword(value); }}>
                                    <ArrowRight width={21} height={21} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default NormalSearch;