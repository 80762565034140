import api from "../utils/api";

export const fetchPlans = async () => {
    try {
        const response = await api.get(`/payment/plans`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const fetchInvoices = async ({ id }) => {
    try {
        const response = await api.get(`/payment/subscription/invoices`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
}

export const subscribePlan = async (body) => {
    try {
        const response = await api.post(`/payment/subscription/create`, body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
export const unsubscribePlan = async ({ subscriptionId, cancelReason }) => {
    try {
        const response = await api.post(`/payment/subscription/${subscriptionId}/cancel`, {
            cancelReason
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};


export const paymentVerified = async (body) => {
    try {
        const response = await api.post(`/payment/verify`, body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const validCoupon = async (body) => {
    try {
        const response = await api.post(`coupons/valid-coupon`, body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};