import { Plus } from "iconoir-react";
import GlobeIcon from "../asset/svg/GlobeIcon";
import LinkedinAvatar from "../asset/svg/LinkedinAvatar";
import LinkedinComment from "../asset/svg/LinkedinComment";
import { ReactComponent as LinkedinGroupReaction } from '../asset/svg/linkedinGroupReaction.svg';
import LinkedinRepost from "../asset/svg/LinkedinRepost";
import LinkedinSend from "../asset/svg/LinkedinSend";
import LinkedInThumbUp from "../asset/svg/LinkedinThumbUp";
import React, { useState, useEffect, useRef } from 'react';
import { NavArrowLeft, NavArrowRight, ArrowLeft, ArrowRight } from 'iconoir-react';
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';
import ContentExpand from "./ContentExpand";
// Set the local worker source for pdf.js
GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const ImageComponent = ({ imageURLs }) => {
  const imageCount = imageURLs?.length;

  if (imageCount === 0) {
    return null;
  }

  if (imageCount === 1) {
    return (
      <div className="mt-2 rounded-lg overflow-hidden mx-auto">
        <img src={imageURLs[0]} alt="image1" className="w-full h-full object-cover max-h-[360px]" />
      </div>
    );
  }

  if (imageCount === 2) {
    return (
      <div className="grid mt-2 grid-cols-2 gap-1">
        <img src={imageURLs[0]} alt="image1" className="w-full h-full rounded-lg overflow-hidden object-cover max-h-[400px]" />
        <img src={imageURLs[1]} alt="image2" className="w-full h-full rounded-lg overflow-hidden object-cover max-h-[400px]" />
      </div>
    );
  }

  if (imageCount >= 3) {
    return (
      <div className="grid mt-2 grid-cols-2 gap-1 overflow-hidden">
        <img src={imageURLs[0]} alt="image1" className="w-full h-full rounded-lg object-cover max-h-[260px]" />

        <div className="grid grid-rows-2 gap-1 h-full">
          <div>
            <img src={imageURLs[1]} alt="image2" className="w-full h-[120px] rounded-lg object-cover" />
          </div>
          <div className="relative">
            <img src={imageURLs[2]} alt="image3" className="w-full h-[120px] rounded-lg object-cover" />
            {
              imageCount > 3 && (
                <div className="absolute z-10 inset-0 flex justify-center items-center bg-gradient-to-b from-transparent to-black rounded-lg">
                  <p className="text-subHeadingOne text-white">
                    +{imageCount - 3}
                  </p>
                </div>
              )
            }
          </div>
        </div>
      </div>

    );
  }

};

const PdfViewer = ({ uploadedFiles }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const canvasRef = useRef(null);
  const file = uploadedFiles[0]?.file

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await getDocument(arrayBuffer).promise;

        setTotalPages(pdfDoc.numPages);
        renderPage(currentPage, pdfDoc);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    loadPdf();
  }, [file, currentPage]);

  const renderPage = async (pageNumber, pdfDoc) => {
    try {
      const page = await pdfDoc.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({
        canvasContext: context,
        viewport: viewport,
      }).promise;
    } catch (error) {
      console.error("Error rendering page:", error);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className="h-[100%] mx-auto bg-white shadow-sm overflow-hidden">

      {/* File Name */}
      <div className="text-white px-4 py-2 text-sm flex items-center gap-2" style={{ background: "rgba(39, 39, 39, 0.57)" }}>
        <span className="font-medium w-[200px] text-nowrap overflow-hidden text-ellipsis">{file?.name}</span>
        <span className="text-white/60">•</span>
        <span className="text-white/60">{totalPages} Pages</span>
      </div>

      {/* Canvas Content */}
      <div className="relative h-full" >
        <canvas ref={canvasRef} className="w-full h-full " />

        {/* Navigation Arrows */}
        <button
          onClick={goToPreviousPage}
          className="absolute left-2 top-[43%] -translate-y-1/2 bg-black/50 rounded-full p-1"
        >
          <NavArrowLeft className="h-6 w-6 text-white" />
        </button>
        <button
          onClick={goToNextPage}
          className="absolute right-2 top-[43%] -translate-y-1/2 bg-black/50 rounded-full p-1 "
        >
          <NavArrowRight className="h-6 w-6 text-white" />
        </button>

        {/* Progress Bar */}
        <div className="absolute gap-2 items-center bottom-9 w-full  flex h-10 p-4" style={{ background: "rgba(39, 39, 39, 0.57)" }}>
          <div className="text-white inline text-sm">{currentPage}/{totalPages}</div>
          <div className="h-1 bg-slate-500  rounded-full w-full flex-1">
            <div
              className="h-full bg-white rounded-full transition-all duration-300"
              style={{ width: `${(currentPage / totalPages) * 100}%` }}
            />
          </div>
        </div>
      </div>
    </div>

  )
}


const LinkedInPostCard = ({ content, name, profilePhoto, headLine, imageUrl, videoUrl, pdfUrl, uploadedFiles }) => {

  return (
    <div className="bg-white px-3 max-w-[555px] w-full rounded-lg py-4 font-inter " style={{
      border: "1px solid #DEDDDA"
    }}>
      <div className="flex gap-2">
        <LinkedinAvatar width="48" height="48" src={profilePhoto} />
        <div className="">
          <p className="text-sm font-semibold">{name}</p>
          {
            headLine ? (
              <p className="text-xs font-normal" style={{
                color: '#666'
              }}>{headLine}</p>
            ) : (
              <div className="w-[217px] h-4" style={{ background: '#F4F1ED' }} />
            )
          }

          <p className="text-xs flex gap-1 font-normal" style={{
            color: '#666'
          }}>
            now •
            <GlobeIcon />
          </p>
        </div>
        <div className="gap-2 flex ml-auto px-2 py-[6px] items-center">
          <Plus color="#3B65BE" />
          <p className="text-sm font-bold" style={{ color: "#3B65BE" }}>Follow</p>
        </div>
      </div>
        <div className="mb-2">
        <ContentExpand  content={content} maxLines={2} tracker={true} trackingData="" />
        </div>
      {
        imageUrl?.length > 0 && (
          <div className="mb-2">
          <ImageComponent imageURLs={imageUrl} />
          </div>
        )
      }

      {videoUrl?.length > 0 && (
        <div className="h-[228px] rounded-lg overflow-hidden my-4">
          <video src={videoUrl} controlsList="nodownload" controls className="w-full h-full" />
        </div>
      )}

      {
        pdfUrl?.length > 0 && (
          <div className="h-[450px]  overflow-hidden mb-2">
            <PdfViewer uploadedFiles={uploadedFiles} />
          </div>
        )
      }

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1 text-xs" style={{ color: '#666' }}>
          <LinkedinGroupReaction />
          <p>You and 500 others</p>
        </div>
        <div className="flex items-center text-xs gap-1" style={{ color: "#666" }}>
          <p>250 comments</p>
          <p>•</p>
          <p>30 reposts</p>
        </div>
      </div>
      <div className="flex justify-between pt-1 mt-2 border-t " style={{ borderColor: '#E7E7E7' }}>
        <div className="flex items-center px-2 py-2.5 gap-2">
          <LinkedInThumbUp />
          <p className="text-sm font-bold" >Like</p>
        </div>
        <div className="flex items-center px-2 py-2.5 gap-2">
          <LinkedinComment />
          <p className="text-sm font-bold" >Comment</p>
        </div>
        <div className="flex items-center px-2 py-2.5 gap-2">
          <LinkedinRepost />
          <p className="text-sm font-bold" >Repost</p>
        </div>
        <div className="flex items-center px-2 py-2.5 gap-2">
          <LinkedinSend />
          <p className="text-sm font-bold" >Send</p>
        </div>
      </div>
    </div>
  )
}
export default LinkedInPostCard;