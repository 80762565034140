const GradientSearchIcon = ({ width = '25', height = '26' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 26" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.1559 17.6559C17.461 17.3508 17.9557 17.3508 18.2608 17.6559L22.4275 21.8226C22.7326 22.1277 22.7326 22.6223 22.4275 22.9274C22.1224 23.2325 21.6277 23.2325 21.3226 22.9274L17.1559 18.7608C16.8508 18.4557 16.8508 17.961 17.1559 17.6559Z" fill="url(#paint0_linear_87_3526)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4583 4.40625C7.28743 4.40625 3.90625 7.78743 3.90625 11.9583C3.90625 16.1292 7.28743 19.5104 11.4583 19.5104C13.5476 19.5104 15.4375 18.663 16.8056 17.2914C18.1689 15.9244 19.0104 14.0404 19.0104 11.9583C19.0104 7.78743 15.6292 4.40625 11.4583 4.40625ZM2.34375 11.9583C2.34375 6.92449 6.42449 2.84375 11.4583 2.84375C16.4922 2.84375 20.5729 6.92449 20.5729 11.9583C20.5729 14.4707 19.5554 16.7469 17.9119 18.3947C16.2627 20.0483 13.9794 21.0729 11.4583 21.0729C6.42449 21.0729 2.34375 16.9922 2.34375 11.9583Z" fill="url(#paint1_linear_87_3526)" />
            <defs>
                <linearGradient id="paint0_linear_87_3526" x1="16.6725" y1="22.5261" x2="23.8505" y2="21.0619" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#22F5EF" />
                    <stop offset="0.49" stopColor="#7145FD" />
                    <stop offset="1" stopColor="#F7AFF1" />
                </linearGradient>
                <linearGradient id="paint1_linear_87_3526" x1="1.53357" y1="19.0677" x2="24.3728" y2="14.4092" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#22F5EF" />
                    <stop offset="0.49" stopColor="#7145FD" />
                    <stop offset="1" stopColor="#F7AFF1" />
                </linearGradient>
            </defs>
        </svg>
    )
}
export default GradientSearchIcon;