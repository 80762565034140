import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { DiceThree, Filter, Plus } from "iconoir-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoaderGif from "../../asset/gif/loader.gif";
import FreeTrialBg from '../../asset/image/freeTrialBg.png';
import ClockCountDown from '../../asset/svg/ClockCountDown';
import Crown from '../../asset/svg/Crown';
import MicstageIcon from "../../asset/svg/MicStageIcon";
import NoPostFound from "../../asset/svg/noPostFound.svg";
import PenNibIcon from "../../asset/svg/PenNibIcon";
import ThumbsUpIcon from "../../asset/svg/ThumbupIcon";
import SearchField from "../../component/custom-form/SearchField";
import SelectBox from "../../component/custom-form/SelectBox";
import FreePlanLimitDialog from '../../component/FreePlanLimitDialog';
import MasonaryLayout from "../../component/MasonaryLayout";
import PostCard from "../../component/PostCard";
import useScrolledDown from "../../hooks/useScrolledDown";
import MainLayout from "../../layout/main";
import { updateSubscription } from '../../redux/slice/auth.slice';
import { setPostIds } from '../../redux/slice/post.slice';
import { fetchBookmarkIds, fetchLinkedInPosts } from "../../services/post.service";
import { isObjectEmpty, nearestRoundOff } from '../../utils/helper';
import { trackEvent } from '../../utils/mixpanel';
import { MIXPANEL_EVENTS } from '../../utils/mixpanel/eventType';

import BlurCard from "../../component/BlurCard.js";
import { contentLengthOption, contentTimeOption, contentToneOption, likesOption, sortByOption } from './helper.js';
import { fetchFavCreatorPost } from '../../services/favCreator.service.js';
import FavCreatorBox from '../../component/custom-form/FavCreatorBox.js';
import AddCreatorPopup from './addCreatorPopup.js';

const trackFilterEvent = (eventName, name, value) => {
    trackEvent(MIXPANEL_EVENTS.FILTER_CLICKED, {
        "Filter type": name,
        "Filter Source": 'Favourite creator'
    })
    trackEvent(eventName, {
        [`${name} Filter`]: value,
        "Filter Source": 'Favourite creator'
    });
}

// function getIndexFromSearchUsage(searchUsage, totalObjects) {
//     return searchUsage % totalObjects;
// }

const FavouriteCreator = () => {
    const authState = useSelector((state) => state.auth);
    const favCreatorState = useSelector((state => state.favCreator));
    const selectedCreatorsIds = favCreatorState.selectedCreatorsIds;
    const totalCreators = favCreatorState.creatorIds?.length || 0;
    const isFreeTrial = authState.subscription.isFreeTrial;
    const dispatch = useDispatch();

    let { scrolledDown: sticky, isScrollingDown } = useScrolledDown(40);
    const [text, setText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [likeFilter, setLikeFilter] = useState({});
    const [contentToneFilter, setContentToneFilter] = useState({});
    const [contentTimeFilter, setContentTimeFilter] = useState({});
    const [sortBy, setSortBy] = useState({
        // label: 'Latest first',
        // value: 'publishedDate:desc',
    },);
    const [contentLengthFilter, setContentLengthFilter] = useState({});
    const [isUnstuck, setIsUnstuck] = useState(false);
    const [openLimitDialog, setOpenLimitDialog] = useState(false);
    const [page, setPage] = useState(1);
    const [addCreatorModel, setAddCreatorModel] = useState(false);

    const query = {
        search: searchText,
        minContentLength: contentLengthFilter?.value?.min,
        maxContentLength: contentLengthFilter?.value?.max,
        minLikes: likeFilter?.value?.min,
        maxLikes: likeFilter?.value?.max,
        reactionFunny: contentToneFilter?.value === 'funny' ? true : undefined,
        reactionInsightful: contentToneFilter?.value === 'insightful' ? true : undefined,
        reactionShareworthy: contentToneFilter?.value === 'shareworthy' ? true : undefined,
        startDate: contentTimeFilter?.value?.startDate,
        endDate: contentTimeFilter?.value?.endDate,
        creators: selectedCreatorsIds,
        options: {
            page,
            sortBy: sortBy?.value,
        }
    };
    const { data, isLoading } = useQuery({
        queryKey: ['favcreator-posts', query],
        queryFn: async ({ queryKey }) => {
            const response = await fetchFavCreatorPost(queryKey[1]);
            dispatch(updateSubscription({ subscriptionLimit: response.subscriptionLimit, subscription: response.subscription }));
            return response;
        },
        staleTime: Infinity,
        retry: false,
    });

    useQuery({
        queryKey: ['bookmark-ids'],
        queryFn: async () => {
            const response = await fetchBookmarkIds();
            dispatch(setPostIds(response.data));
        },
        staleTime: Infinity,
        retry: false
    });


    // console.log(isFetching)
    const handlePressEnter = (value) => {
        setText(value);
        setSearchText(value);
    }
    const handleClearSearch = () => {
        setText('');
        setSearchText('');
    }

    const resetAllFilter = () => {
        trackEvent(MIXPANEL_EVENTS.FILTERS_REMOVED, {
            'Filter source': 'Favourite creator'
        });
        setLikeFilter({});
        setContentToneFilter({});
        setContentLengthFilter({});
        setContentTimeFilter({});
        setSortBy({})
    }

    const activeFilter = Object.keys(likeFilter).length || Object.keys(contentLengthFilter).length || Object.keys(contentToneFilter).length || Object.keys(contentTimeFilter).length

    const filterCount = !isObjectEmpty(likeFilter) + !isObjectEmpty(contentToneFilter) + !isObjectEmpty(contentLengthFilter);
    sticky = isUnstuck ? false : sticky;

    useEffect(() => {
        setIsUnstuck(false);
    }, [isScrollingDown])
    const handleUnStick = () => {
        setIsUnstuck(true);
    }


    const metaData = {
        search: searchText,
        postFrom: 'Favourite Creator',
        postTone: contentToneFilter?.value
    }

    const { subscription } = authState;
    const totalPost = (data?.pagination?.total - 6);
    return (
        <MainLayout>
            {
                openLimitDialog && (
                    <FreePlanLimitDialog title={'This premium feature is available in a paid plan'} subTitle={'80% of SuperPen’s paid users claim that this filter is the most useful.'} onClose={() => { setOpenLimitDialog(false) }} />
                )
            }
            <div className="rounded-2xl  relative bg-star-light3 " style={{
                marginTop: subscription.isFreeTrial ? 12 : undefined,
            }}>

                <div className='rounded-2xl mr-[10px] fixed z-20  top-0 w-full right-0' style={{
                    width: 'calc(100% - 230px)',
                    top: subscription.isFreeTrial ? sticky ? 48 : 65 : sticky ? 0 : 10
                }}>

                    <>
                        {!sticky && (
                            <div className='flex rounded-2xl pt-[44px] pb-4 bg-star-light3 px-4 justify-between items-center'>
                                <p className='text-headingThree text-space-default'>Favourite Creators <span className='text-subHeadingTwo text-gray-6'>| {totalCreators}</span> </p>
                                <button className='filled-btn'
                                    onClick={() => {
                                        setAddCreatorModel(true);
                                        trackEvent(MIXPANEL_EVENTS.ADD_CREATOR_CLICKED, {
                                            'Creator added from': 'Add button'
                                        })
                                    }}
                                >
                                    <Plus />
                                    Add Creator
                                </button>
                            </div>
                        )

                        }
                        <div className={`flex bg-star-light3 p-4 items-center ${sticky ? 'flex-row gap-4' : 'flex-col'}`}>
                            <div className="w-[504px]">
                                <SearchField showDropDown={false} value={text} onChange={setText} onPressEnter={handlePressEnter} onClear={handleClearSearch} placeholder='Search any topic and discover viral posts' />
                            </div>

                            {
                                !sticky && (
                                    <div className="flex mt-6 w-full justify-center items-center  gap-2">
                                        <div className='flex ml-auto justify-center items-center gap-2 pl-14'>
                                            <SelectBox
                                                value={likeFilter}
                                                icon={<ThumbsUpIcon />}
                                                label="Likes"
                                                options={likesOption}
                                                onRemoveFilter={() => { setLikeFilter({}) }}
                                                onChange={(val) => {
                                                    trackFilterEvent(MIXPANEL_EVENTS.FILTER_LIKES, 'Likes', val.label);
                                                    setLikeFilter(val);
                                                }}
                                            />
                                            <SelectBox
                                                value={contentToneFilter}
                                                icon={<MicstageIcon />}
                                                label="Content Tone"
                                                options={contentToneOption}
                                                onRemoveFilter={() => { setContentToneFilter({}) }}
                                                onChange={(val) => {
                                                    trackFilterEvent(MIXPANEL_EVENTS.FILTER_TONE, 'Tone', val.label);
                                                    setContentToneFilter(val);
                                                }}
                                            />
                                            <SelectBox
                                                value={contentLengthFilter}
                                                icon={<PenNibIcon />}
                                                label="Length"
                                                options={contentLengthOption}
                                                onRemoveFilter={() => { setContentLengthFilter({}) }}
                                                onChange={(val) => {
                                                    trackFilterEvent(MIXPANEL_EVENTS.FILTER_LENGTH, 'Length', val.label);
                                                    setContentLengthFilter(val);
                                                }}
                                            />
                                            <SelectBox
                                                value={contentTimeFilter}
                                                icon={<ClockCountDown />}
                                                label="Date"
                                                options={contentTimeOption}
                                                onRemoveFilter={() => { setContentTimeFilter({}) }}
                                                onChange={(val) => {
                                                    trackFilterEvent(MIXPANEL_EVENTS.FILTER_TIME, 'Time Period', val.label);
                                                    setContentTimeFilter(val);
                                                }}
                                            />
                                            <FavCreatorBox />
                                        </div>

                                        {
                                            activeFilter > 0 && (
                                                <button onClick={resetAllFilter} className="text-btn small text-gray-6">Remove all filters</button>
                                            )
                                        }

                                        <div className='ml-auto'>
                                            <SelectBox
                                                value={sortBy}
                                                // icon={<ClockCountDown />}
                                                label="Sort"
                                                options={sortByOption}
                                                onRemoveFilter={() => { setSortBy({}) }}
                                                onChange={(val) => {
                                                    trackEvent(MIXPANEL_EVENTS.SORT_CLICKED, {
                                                        "Sort property": val.label
                                                    })
                                                    // trackFilterEvent(MIXPANEL_EVENTS.FILTER_TIME, 'Time Period', val.label);
                                                    setSortBy(val);
                                                }}
                                                removeBtnText='Remove' // optional
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {
                                sticky && (
                                    <div className="ml-auto">
                                        <button className="icon-outline-btn small relative" onClick={handleUnStick}>
                                            <Filter />
                                            {
                                                filterCount > 0 && (
                                                    <div className='flex items-center justify-center absolute -top-1 -right-1 rounded-full w-[13px] h-[13px] bg-mars-default text-white text-subTextBold'>
                                                        {filterCount}
                                                    </div>
                                                )
                                            }
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </>

                </div>
                <div className="p-4 pt-[264px]">
                    {
                        isLoading && (
                            <div className="flex flex-col h-[70vh] justify-center items-center">
                                <img src={LoaderGif} width={200} alt="loading" />
                                <p className="text-bodyThreeBold text-star-light">Hang tight, inspiration is on the way!</p>
                            </div>
                        )
                    }
                    {
                        data?.data?.length === 0 && (
                            <div className="flex flex-col h-[70vh] justify-center items-center">
                                <img src={NoPostFound} alt="notFound" />
                                <p className="text-bodyThreeBold text-star-light text-center"> No post found!</p>
                            </div>
                        )
                    }
                    {
                        !isLoading && (
                            <MasonaryLayout>
                                {
                                    data?.data?.map((postItem) => (
                                        postItem?.isFreeTrial ?
                                            <div key={postItem.postId} className="flex-1">
                                                <BlurCard {...postItem} postType={contentToneFilter?.value} metaData={metaData} />
                                            </div>
                                            :
                                            <div key={postItem.postId} className="flex-1">
                                                <PostCard {...postItem} postType={contentToneFilter?.value} metaData={metaData} />
                                            </div>
                                    ))
                                }
                            </MasonaryLayout>
                        )
                    }
                    {
                        !isFreeTrial && !isLoading && data?.data?.length > 0 && data?.pagination?.remainingPosts > 0 && (
                            <div className='flex items-center justify-center my-4'>
                                <button className='outlined-btn small border-none' onClick={() => {
                                    setPage((prev) => prev + 1);
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth', // Adds smooth scrolling effect
                                    });
                                }}>
                                    <DiceThree />
                                    Renew inspiration feed
                                </button>
                            </div>
                        )
                    }

                </div>
                {
                    isFreeTrial && nearestRoundOff(totalPost) > 0 && !isLoading && (
                        <div className='p-4'>
                            <div className='rounded-xl relative w-full pt-[47px] pb-[89px] '>
                                <img src={FreeTrialBg} alt='background-img' className='w-full top-0 h-full absolute z-0' />
                                <div className='flex flex-col items-center justify-center relative z-10'>
                                    <p className='text-headingOne text-white text-center'>{nearestRoundOff(totalPost)}+ posts found</p>
                                    <p className='text-subHeadingThree mt-3 mb-5 text-white text-center'>Need more inspiration?
                                    </p>
                                    <Link to={'/payment-pending'} className='filled-btn white small'><Crown /> <p >Upgrade Plan Now</p> </Link>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            {addCreatorModel && <AddCreatorPopup onClose={() => {
                setAddCreatorModel(false)
            }} />}

        </MainLayout>
    )
}

export default FavouriteCreator;