import React, { useState, useEffect, useRef } from 'react';
import { NavArrowLeft, NavArrowRight, ArrowLeft } from 'iconoir-react';
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';

// Set the local worker source for pdf.js
GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export default function PdfViewer({ file, onClose }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const canvasRef = useRef(null);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await getDocument(arrayBuffer).promise;

        setTotalPages(pdfDoc.numPages);
        renderPage(currentPage, pdfDoc);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    loadPdf();
  }, [file, currentPage]);

  const renderPage = async (pageNumber, pdfDoc) => {
    try {
      const page = await pdfDoc.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({
        canvasContext: context,
        viewport: viewport,
      }).promise;
    } catch (error) {
      console.error("Error rendering page:", error);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50" style={{background:"rgba(106, 101, 167, 0.52)",backdropFilter:"blur(12px)"}}>
      <div className="relative bg-white rounded-[25px] w-[90vw] max-w-[700px] h-[80vh] p-6">
        <div className="flex justify-between items-center mb-4">
          <div className="text-sm text-star-light w-[200px] text-nowrap overflow-hidden text-ellipsis">{file?.name}</div>
          <div className="text-sm text-star-light">
            Page {currentPage} of {totalPages}
          </div>
        </div>

        <div className="relative h-[calc(100%-8rem)] w-full bg-gray-100 rounded-xl overflow-hidden mt-3">
          <canvas ref={canvasRef} className="w-full h-full" />
        </div>

        <div className="flex justify-between items-center mt-8">
          <button
            onClick={() => onClose(false)}
            className="text-btn small" type="button"
          >
            <ArrowLeft />
            Back
          </button>

          <div className="flex gap-3">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed border border-[var(--SP-Star-Light,#A489F3)]"
            >
              <NavArrowLeft className="w-5 h-5 text-star-light" />
            </button>
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed border border-[var(--SP-Star-Light,#A489F3)]"
            >
              <NavArrowRight className="w-5 h-5 text-star-light" />
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}
