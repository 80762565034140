import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateOnboarding } from "../../redux/slice/auth.slice";
import { saveOnBoarding } from "../../services/onboardin.service";
import ConnectLinkedin from "./ConnectLinkedin";
import GoalForm from "./GoalForm";
import RoleForm from "./RoleForm";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const OnBoarding = () => {
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);
    const [activeStep, setActiveStep] = useState(authState?.onboardingStep - 1);
    const [enableOther, setEnableOther] = useState(false);
    const [otherText, setOtherText] = useState('');

    const [formData, setFormData] = useState({
        occupation: authState?.occupation || '',
        interestedPlatform: authState?.interestedPlatform || [],
        interest: [],
    });

    // console.log(formData);
    const { mutate } = useMutation({
        mutationFn: saveOnBoarding,
        onSuccess: (data) => {
            dispatch(updateOnboarding(data.data));
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong!')
        }
    });
    const handleNext = (defaultValue) => {
        mutate(defaultValue || formData)
        setActiveStep((prevStep) => prevStep + 1);
        trackEvent(MIXPANEL_EVENTS.ONBOARDING_NEXT, {
            "Onboarding screen (next)": activeStep === 0 ? "Persona" : "Interests"
        });
    };

    const handleBack = () => {
        if (activeStep === 0) {
            return;
        }
        if (activeStep === 1) {
            setOtherText('');
            setEnableOther(false);
            setFormData((prev) => {
                return {
                    ...prev,
                    interest: []
                }
            })
        }
        setActiveStep((prevStep) => prevStep - 1);
        trackEvent(MIXPANEL_EVENTS.ONBOARDING_BACK, {
            "Onboarding screen (back)": activeStep === 0 ? "Persona" : activeStep === 1 ? "Interests" : "Linkedin connect"
        });
    };

    const handleChange = (name, value) => {
        setFormData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    };

    const pageComponent = [
        <RoleForm
            value={formData.occupation}
            interestedPlatform={formData.interestedPlatform}
            onChange={handleChange}
            handleNext={handleNext}
        />,
        <GoalForm
            type={formData.occupation}
            value={formData.interest}
            onChange={handleChange}
            handleBack={handleBack}
            handleNext={handleNext}
            enableOther={enableOther}
            setEnableOther={setEnableOther}
            otherText={otherText}
            setOtherText={setOtherText}
        />,
        <ConnectLinkedin
            handleBack={handleBack}
            handleNext={handleNext}
        />,
        // <PlanBoard />
    ]
    return (
        <div className="h-screen flex items-center justify-center bg-star-light3 backdrop-blur-[7.5px]">
            <div className="w-4/5 z-10 relative bg-star-light4 [border-radius:30px] p-9">
                {pageComponent[activeStep]}
            </div>
        </div>
    )
}

export default OnBoarding;