import { ArrowLeft, ArrowRight, Copy, FastArrowLeft, FastArrowRight } from "iconoir-react";
import { useState, useRef, useEffect } from "react";
import LightBulbIcon from "../../asset/svg/LightBulbIcon";
import ThumbsUpIcon from "../../asset/svg/ThumbupIcon";
import ContentExpand from "../../component/ContentExpand";
import TextEditor from "../../component/text-editor";
import { copyToClipboard } from "../../component/text-editor/copyClipboard";
import { incrementUserProperty, setUserProperties, trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import { useSelector } from "react-redux";
import { Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import LoaderGif from "../../asset/gif/loader.gif";
import { InfoCircleSolid, Sparks } from 'iconoir-react';
import PERMISSIONS, { hasPermission } from '../../utils/permissions';

const LeftSide = ({ content, selectedComment }) => {
    const [show, setShow] = useState(true);
    const handleToggle = () => {
        setShow(prev => !prev);
    }
    return (
        <div className="rounded-[20px] p-4 overflow-auto h-[95vh] scrollbar bg-star-light3">
            <div className="flex items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.52392 2.83371C5.84405 2.71383 5.19573 3.16779 5.07585 3.84766L2.64478 17.635C2.5249 18.3148 2.97886 18.9632 3.65873 19.083L15.4764 21.1668C16.1563 21.2867 16.8046 20.8327 16.9245 20.1529L19.3556 6.36556C19.4754 5.68569 19.0215 5.03737 18.3416 4.91749L6.52392 2.83371ZM3.59864 3.58719C3.86237 2.09148 5.28868 1.09277 6.78439 1.3565L18.6021 3.44028C20.0978 3.70401 21.0965 5.13032 20.8328 6.62603L18.4017 20.4133C18.138 21.9091 16.7117 22.9078 15.2159 22.644L3.39825 20.5603C1.90254 20.2965 0.903831 18.8702 1.16756 17.3745L3.59864 3.58719Z" fill="#7145FD" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.19056 6.25139C7.26249 5.84347 7.65149 5.5711 8.05941 5.64302L15.9379 7.03221C16.3458 7.10414 16.6182 7.49313 16.5462 7.90105C16.4743 8.30897 16.0853 8.58135 15.6774 8.50942L7.79893 7.12023C7.39101 7.04831 7.11864 6.65931 7.19056 6.25139Z" fill="#7145FD" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49598 10.1906C6.56791 9.78271 6.95691 9.51034 7.36483 9.58226L15.2433 10.9714C15.6512 11.0434 15.9236 11.4324 15.8517 11.8403C15.7797 12.2482 15.3907 12.5206 14.9828 12.4487L7.10435 11.0595C6.69643 10.9875 6.42406 10.5986 6.49598 10.1906Z" fill="#7145FD" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.80128 14.1299C5.87321 13.722 6.2622 13.4496 6.67012 13.5215L11.5942 14.3897C12.0021 14.4617 12.2745 14.8507 12.2025 15.2586C12.1306 15.6665 11.7416 15.9389 11.3337 15.867L6.40965 14.9987C6.00173 14.9268 5.72935 14.5378 5.80128 14.1299Z" fill="#7145FD" />
                </svg>
                <p className="text-bodyOneBold text-star-dark">Template in use</p>
                {
                    show && (
                        <div className="flex gap-2 text-bodyTwo text-star-default items-center cursor-pointer ml-auto" onClick={handleToggle}>
                            <p>Hide</p>
                            <FastArrowLeft />
                        </div>
                    )
                }
            </div>
            {
                !show && (
                    <div className="flex gap-2 justify-end text-bodyTwo text-star-default items-center cursor-pointer mt-2" onClick={handleToggle}>
                        <p>Show</p>
                        <FastArrowRight />
                    </div>
                )
            }
            {
                show && (
                    <>
                        <div className="rounded-xl bg-gray-moon p-4 my-4">
                            <ContentExpand content={content} maxLines={3} defaultValue={!selectedComment?.length} templateFormat={true} />
                        </div>
                        <div className="mt-4">
                            {
                                selectedComment?.length > 0 && (
                                    <p className="text-bodyTwo text-space-default">Relevant comments</p>
                                )
                            }
                            <div className="flex flex-col mt-4 gap-4">
                                {
                                    selectedComment?.map((comment) => (
                                        <div key={comment._id} className="rounded-xl p-2 bg-star-light4 flex gap-2">
                                            <div className="min-w-5">
                                                <ThumbsUpIcon firstColor="#5F38DB" secondColor="#A489F3" />
                                            </div>
                                            <p className="text-bodyTwo text-gray-6">
                                                {comment?.commentText}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}
const getDifferenceInSeconds = (date1, date2) => {
    const diffInMilliseconds = Math.abs(date2 - date1); // Get difference in milliseconds
    return Math.floor(diffInMilliseconds / 1000); // Convert milliseconds to seconds
};
const WritingScreen = (props) => {
    const { subscriptionLimit } = useSelector(state => state.auth);
    const remainingCount = (subscriptionLimit.aiCreditLimit - subscriptionLimit.aiCreditUsage);
    const editor = useRef(null);
    useEffect(() => {
        editor.current.focus();
    }, []);
    const generatePermission = hasPermission(PERMISSIONS.AI_GENERATE);
    const remainingText = remainingCount === subscriptionLimit.aiCreditLimit ? remainingCount - 1 : remainingCount;
   
    const {
        selectedComment,
        content,
        handleBack,
        handleNext,
        editorState,
        setEditorState,
        handleGenerateNewPost,
        isAiGenerated,
        postGenerating,
        metaData,
        setMedia,
        onClose,
        uploadedFiles,
        setUploadedFiles,
        image,
        setIsImage
    } = props;

    const trackCopyClicked = () => {
        trackEvent(MIXPANEL_EVENTS.CONTENT_COPY, {
            "Copy From": 'writing_screen',
            "Page source":metaData.postFrom,
            "Post Id": props.postId,
            "Post Source": props.source,
            "Generated with AI": isAiGenerated,
            "Manual changes": !isAiGenerated,
        })

        incrementUserProperty('Number of posts copied');
    }

    const trackBackClicked = () => {
        trackEvent(MIXPANEL_EVENTS.WRITING_BACK, {
            "Remaining AI credits": remainingCount,
            "Page source": metaData.postFrom,
            "Post Id": props.postId,
            "Generated with AI": isAiGenerated,
            "Manual changes": !isAiGenerated
        })
    }

    const trackAIClicked = () => {
        trackEvent(MIXPANEL_EVENTS.AI_CLICKED, {
            "Number of comments selected": selectedComment.length,
            "Post Id": props.postId,
            "Post Source": props.source,
            "Remaining AI credits": remainingCount,
            "AI chosen stage": 'writing_page',
            "Manual before AI": true,
        })
    }

    const showPreviewClicked = () => {
        trackEvent(MIXPANEL_EVENTS.PREVIEW_CLICKED, {
            "Generated with AI": isAiGenerated,
            "Post Id": props.postId,
            "Page source":metaData.postFrom,
            "Post Source": props.source,
            "Manual changes": !isAiGenerated,
            "Time lapsed (start to preview)": getDifferenceInSeconds(props.startTime, new Date())
        })
    }
    const words = editorState.getCurrentContent().getPlainText('').split(/\s+/).filter(word => word.trim() !== '');
    const disabled = editorState.getCurrentContent().getPlainText() === '';

    return (
        <div className="z-50 h-screen w-screen fixed top-0 left-0 p-5 bg-star-light2">
            <div className="flex  gap-5 h-[95vh]">

                {/* Left Side */}
                <div className="max-w-[32%]">
                    <LeftSide content={content} selectedComment={selectedComment} />
                </div>

                {/* Right Side */}
                <div className="flex flex-1 flex-col gap-5 rounded-[20px] bg-gray-moon p-[30px] h-[95vh]">
                    <div className="flex items-center justify-center gap-2 mt-3">
                        {/* <div className="rounded-2xl bg-star-light w-[52px] h-[5px]" /> */}
                        <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                        <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                        <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                        
                    </div>
                    <div className='mt-4'>
                        <p className="flex items-center justify-center text-subHeadingTwoBold bg-clip-text text-transparent bg-hover-gradient-1">
                            Add your Insights
                        </p>
                        <p className="flex items-center justify-center text-bodyOne text-gray-6">
                            Just write your raw thoughts to plugin in the selected template
                        </p>
                    </div>

                    <div className="h-full mt-5">

                        <div className="bg-white z-0 relative overflow-auto h-[320px] px-[14px] pr-[100px] py-2.5 rounded-lg border border-gray-3 text-bodyThree text-gray-5  scrollbar" style={{ wordBreak: 'break-word' }}>
                            <Editor
                                ref={editor}
                                editorState={editorState}
                                onChange={setEditorState}
                                handleKeyCommand={(command) => {
                                    const newState = RichUtils.handleKeyCommand(editorState, command);
                                    if (newState) {
                                        setEditorState(newState);
                                        return 'handled';
                                    }
                                    return 'not handled';
                                }}
                                placeholder="Start writing your raw thoughts..."
                                setMedia={setMedia}
                            />
                                           <div className={`absolute text-subText text-gray-5 bottom-2 right-4 ${words.length > 400 ? 'text-mars-default' : ''}`}>
                        {words.length} words
                    </div>
                            {
                                postGenerating && (
                                    <div className='absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center'>
                                        <img src={LoaderGif} width={200} alt='loading' />
                                        <p className='text-gray-5'>Generating...</p>
                                    </div>
                                )
                            }
       
                        </div>
                    </div>
     
                    <div className="flex gap-1 justify-end pt-3">
                                    {
                                        generatePermission && (
                                            <>
                                                <p className="text-bodyThree text-gray-5">
                                                    <b>{remainingText}</b> of {subscriptionLimit.aiCreditLimit - 1} credits left
                                                </p>
                                                <p className="text-bodyThree text-gray-5">•</p>
                                            </>
                                        )
                                    }

                                    <p className="text-bodyThree text-gray-4">powered by ChatGPT</p>
                                </div>
                    <div className="flex justify-between items-center mt-auto">
                        <button className="text-btn small" onClick={() => {
                            trackBackClicked();
                            handleBack();
                        }}>
                            <ArrowLeft /> Back
                        </button>

                        <div className='flex justify-between items-center mt-2'>
                            <div className='items-center gap-2'>

                                <button className='ai-btn small' onClick={() => {
                                    handleGenerateNewPost('createTemplate');
                                    if (remainingText >= 1) {
                                        handleNext()
                                    }
                                }} disabled={postGenerating || remainingText <= 0 || disabled}>
                                    <Sparks />
                                    Create post with AI
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}

export default WritingScreen;