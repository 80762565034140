import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";
import RegisterHeroImage from "../../asset/image/register-hero.png";
import InputField from "../../component/form/InputField";
import PasswordInputField from "../../component/form/PasswordInputField";
import GoogleAuthButton from "../../component/GoogleAuthButton";
import { EXTERNAL_LINKS } from "../../constent/links";
import Header from "../../layout/auth/Header";
import { sendOTPService } from "../../services/auth.service";
import { trackEvent, trackPageView } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import VerifyOTP from "./verifyOTP";
import useResponsive from "../../hooks/useResponsive";
import MiniLogo from "../../asset/svg/MiniLogo";
import { ArrowLeft, Mail } from "iconoir-react";
import { isPending } from "@reduxjs/toolkit";

const registerSchema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    email: z.string().email("Invalid email address"),
    password: z.string().min(4, "Password must be at least 4 characters"),
});

const trackLoginClick = () => {
    trackEvent(MIXPANEL_EVENTS.LOGIN_CLICKED, {
        "Log in click source": "signup"
    })
}


const Register = () => {
    const { isMobile } = useResponsive();

    const [openOTPScreen, setOpenOTPScreen] = useState(false);

    const { handleSubmit, control, setError, getValues, } = useForm({
        resolver: zodResolver(registerSchema),
        defaultValues: {
            name: '',
            email: '',
            password: ''
        }
    });

    const { mutate: mututeSendOTP, isPending: isPendingSendOTP } = useMutation({
        mutationFn: sendOTPService,
        onSuccess: () => {
            setOpenOTPScreen(true);
            trackEvent(MIXPANEL_EVENTS.OTP_SENT, {
                email: getValues("email"),
                name: getValues("name")
            });
        },
        onError: (error) => {
            setError("email", { type: 'validate', message: error?.message || 'Invalid email address' });
            trackEvent(MIXPANEL_EVENTS.SIGNUP_FAILURE, {
                "Reason (sign-up failure)": error?.message || 'Server Error'
            })
        }
    });

    const onSubmit = (data) => {
        mututeSendOTP({ email: data.email, name: data.name });
    };

    useEffect(() => {
        trackPageView(MIXPANEL_EVENTS.SIGNUP_VIEWED);
    }, [])
    return (
        isMobile ? (
            <MobileView
                trackLoginClick={trackLoginClick}
                onSubmit={onSubmit}
                isPendingSendOTP={isPendingSendOTP}
                handleSubmit={handleSubmit}
                control={control}
                openOTPScreen={openOTPScreen}
                getValues={getValues}
            />
        ) : (

            <div className="bg-star-light4 h-screen">
                <Header />
                <div className="container mx-auto grid grid-cols-3 p-8 gap-16 h-5/6">
                    <div className="col-span-2 hidden md:block">
                        <img src={RegisterHeroImage} alt="register-hero-img" className="w-full" />
                    </div>
                    {
                        openOTPScreen ? <VerifyOTP {...getValues()} /> : (
                            <div className="mr-1" >
                                <div>
                                    <p className="text-headingTwo text-space-default ">Let’s get started!</p>
                                    <p className="text-subHeadingTwo bg-heading-gradient-3 bg-clip-text text-transparent">Start for FREE | No card needed</p>
                                </div>
                                <div className="mt-8">
                                    <GoogleAuthButton source='signup' />
                                </div>
                                <div className="flex items-center gap-2 my-4">
                                    <div className="border-b flex-1 border-gray-4" />
                                    <p className="text-bodyTwo text-gray-4">or use email</p>
                                    <div className="border-b flex-1 border-gray-4" />
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="mt-2.5">
                                        <p className="text-bodyTwo text-space-default mb-1">Your Name</p>
                                        <InputField
                                            name={'name'}
                                            control={control}
                                            type='text'
                                            placeholder='eg. Jane smith' />
                                    </div>
                                    <div className="mt-2.5">
                                        <p className="text-bodyTwo text-space-default mb-1">Email</p>
                                        <InputField
                                            name={'email'}
                                            type='email'
                                            control={control}
                                            placeholder='hello@example.com'
                                        />
                                    </div>
                                    <div className="mt-2.5">
                                        <p className="text-bodyTwo text-space-default mb-1">Password</p>
                                        <PasswordInputField
                                            name={'password'}
                                            control={control}
                                            placeholder='4+ characters'
                                        />
                                    </div>
                                    <div className="flex gap-2.5 items-center mt-6">
                                        <div>
                                            <p className="text-subText text-gray-5">
                                                By creating an account,
                                                you agree to the <a target="_blank" rel="noreferrer" href={EXTERNAL_LINKS.terms} className="underline"> Terms of Service.</a>
                                            </p>
                                        </div>
                                        <button className="filled-btn" disabled={isPendingSendOTP} >Create Account</button>
                                    </div>
                                </form>
                                <div className="flex gap-1 mt-4">
                                    <p className="text-bodyTwo text-space-default">Already have an account?</p>
                                    <Link to={'/login?utm_source=signup'} className="text-bodyTwoBold text-star-default" onClick={trackLoginClick}>Login</Link>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    )
}
export default Register;

const MobileView = (props) => {
    const [step, setStep] = useState(0);
    const handleNext = () => {
        setStep((prev) => prev + 1);
    }
    const handleBack = () => {
        setStep((prev) => prev - 1);
    }
    const stepList = [
        <MobileViewFirstPage {...props} handleNext={handleNext} />,
        <MobileViewSecondPage {...props} handleBack={handleBack} />
    ]

    return (
        stepList[step]
    )
}

const MobileViewFirstPage = ({ trackLoginClick, handleNext }) => {
    return (
        <>
            <div className="fixed top-0 w-full left-0">
                <MobileLineSVG />
            </div>
            <div className="bg-star-light4 p-5 border min-h-dvh flex items-center flex-col text-center pt-5">
                <div className="py-2">
                    <MiniLogo />
                </div>
                <p className="text-headingThree text-space-default">Let’s get started!</p>
                <p className="text-subHeadingThree bg-clip-text text-transparent bg-heading-gradient-3">Sign up to SuperPen</p>
                <div className="mt-16">
                    <GoogleAuthButton source='signup' />
                </div>
                <div className="flex items-center gap-2 my-4 w-full">
                    <div className="border-b flex-1 border-gray-4" />
                    <p className="text-bodyTwo text-gray-4">or </p>
                    <div className="border-b flex-1 border-gray-4" />
                </div>
                <div>
                    <button className="outlined-btn small" style={{ borderRadius: '50px' }} onClick={handleNext}><Mail /> Sign up with Email</button>
                </div>
                <div className="mt-6 mb-2">
                    <p className="text-subText text-gray-5">
                        By creating an account,
                        you agree to the <a target="_blank" rel="noreferrer" href={EXTERNAL_LINKS.terms} className="underline"> Terms of Service.</a>
                    </p>
                </div>
                <div className="flex gap-1">
                    <p className="text-bodyTwo text-space-default">Already have an account?</p>
                    <Link to={'/login?utm_source=signup'} className="text-bodyTwoBold text-star-default" onClick={trackLoginClick}>Login</Link>
                </div>
            </div>
        </>
    )
}

const MobileViewSecondPage = ({ handleSubmit, onSubmit, isPendingSendOTP, control, openOTPScreen, handleBack, getValues }) => {
    return (
        openOTPScreen ? (
            <>
                <VerifyOTP {...getValues()} />
            </>
        ) : (
            <>
                <div className="fixed top-0 w-full left-0">
                    <MobileLineSVG />
                </div>
                <div className="bg-star-light4 p-5 border min-h-dvh flex  flex-col pt-5">
                    <div className="py-2 mx-auto">
                        <MiniLogo />
                    </div>
                    <p className="text-headingThree text-center text-space-default">Let’s get started!</p>
                    <p className="text-subHeadingThree text-center bg-clip-text text-transparent bg-heading-gradient-3">Sign up to SuperPen</p>
                    <form onSubmit={handleSubmit(onSubmit)} className="flex w-full flex-col">
                        <div className="mt-2.5">
                            <p className="text-bodyTwo text-space-default mb-1 text-left">Your Name</p>
                            <InputField
                                name={'name'}
                                control={control}
                                type='text'
                                placeholder='eg. Jane smith' />
                        </div>
                        <div className="mt-2.5">
                            <p className="text-bodyTwo text-space-default mb-1 text-left">Email</p>
                            <InputField
                                name={'email'}
                                type='email'
                                control={control}
                                placeholder='hello@example.com'
                            />
                        </div>
                        <div className="mt-2.5">
                            <p className="text-bodyTwo text-space-default mb-1 text-left">Password</p>
                            <PasswordInputField
                                name={'password'}
                                control={control}
                                placeholder='4+ characters'
                            />
                        </div>
                        <div className="flex gap-2.5 items-center mt-5">
                            <div>
                                <p className="text-subText text-gray-5">
                                    By creating an account,
                                    you agree to the <a target="_blank" rel="noreferrer" href={EXTERNAL_LINKS.terms} className="underline"> Terms of Service.</a>
                                </p>
                            </div>
                        </div>
                        <div className="flex gap-4 mt-6 mb-4">
                            <button type="button" className="text-btn small" onClick={handleBack}>
                                <ArrowLeft />
                                Back
                            </button>
                            <button className="filled-btn small w-full" disabled={isPendingSendOTP}>Create Account</button>
                        </div>
                    </form>

                    <div className="flex gap-1">
                        <p className="text-bodyTwo text-space-default">Already have an account?</p>
                        <Link to={'/login?utm_source=signup'} className="text-bodyTwoBold text-star-default" onClick={trackLoginClick}>Login</Link>
                    </div>
                </div>
            </>
        )
    )
}


const MobileLineSVG = () => {
    return (
        <svg width="360" height="117" viewBox="0 0 360 117" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M251.449 -494.355L419.072 -153.813C438.64 -114.059 417.415 -66.2897 374.796 -54.1668L90.5154 26.6967C29.5764 44.0307 -19.8674 -24.0529 15.4717 -76.638L316.603 -524.725" stroke="url(#paint0_linear_173_1926)" stroke-width="16" />
            <defs>
                <linearGradient id="paint0_linear_173_1926" x1="16.266" y1="-310.657" x2="880.203" y2="-146.927" gradientUnits="userSpaceOnUse">
                    <stop offset="0.100482" stopColor="#D6D0FF" />
                    <stop offset="0.33" stopColor="#F7AFF1" />
                    <stop offset="0.635" stopColor="#FFFBF7" />
                </linearGradient>
            </defs>
        </svg>

    )
}