import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import StrokeBackgroundImage from '../../asset/svg/auth-stroke.svg';
import InputField from "../../component/form/InputField";
import PasswordInputField from "../../component/form/PasswordInputField";
import Header from "../../layout/auth/Header";
import { login } from "../../redux/slice/auth.slice";
import { loginService } from "../../services/auth.service";
import GoogleAuthButton from "../../component/GoogleAuthButton";
import { useEffect } from "react";
import { identifyUser, setSuperProperties, trackEvent, trackPageView } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import useResponsive from "../../hooks/useResponsive";
import MiniLogo from "../../asset/svg/MiniLogo";

const trackSuccessLogin = (mode, data) => {
    const { user, subscription } = data;
    identifyUser(user._id, {
        '$name': user.name,
        '$email': user.email,
        interests: user?.interest,
        persona: user?.occupation,
        linkedinConnected: user?.isLinkedinVerified
    })
    setSuperProperties({
        // member_since: user?.createdAt,
        current_plan: subscription?.planName,
        version:"v2.0.2"
        // plan_renewal: subscription?.endDate,
        // persona: user?.occupation,
    })
    trackEvent(MIXPANEL_EVENTS.LOGIN_SUCCESS, {
        "Log in method": mode,
    })
}

const trackSignupClick = () => {
    trackEvent(MIXPANEL_EVENTS.SIGNUP_CLICKED, {
        "Sign-up click source": "login"
    })
}

const loginSchema = z.object({
    email: z.string().email("Invalid email address"),
    password: z.string().min(4, "Password must be at least 4 characters"),
});

const Login = () => {
    const { isMobile } = useResponsive();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleSubmit, control, setError } = useForm({
        resolver: zodResolver(loginSchema),
        defaultValues: {
            email: '',
            password: ''
        }
    });

    const { mutate, isPending } = useMutation({
        mutationFn: loginService,
        onSuccess: (data) => {
            dispatch(login(data));
            trackSuccessLogin('email', data)
            navigate('/');
        },
        onError: (error) => {
            setError("email", { type: 'validate', message: 'Invalid email address' });
            setError("password", { type: 'validate', message: 'Invalid password' });
            trackEvent(MIXPANEL_EVENTS.LOGIN_FAILURE, {
                "Reason (log in failure)": error?.message || 'Server Error'
            })
        }
    });

    const onSubmit = (data) => {
        mutate(data);
    };

    useEffect(() => {
        trackPageView(MIXPANEL_EVENTS.LOGIN_VIEWED);
    }, [])
    return (
        isMobile ? (
            <MobileView
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                control={control}
                isPending={isPending}
            />
        ) : (
            <div className="bg-star-light4 h-screen overflow-hidden">
                <Header />
                <div className="flex relative items-center justify-center h-5/6">
                    <div className="py-6 px-9 rounded-3xl border border-gray-3 bg-gray-moon [width:449px] z-10">
                        <p className="text-headingTwo text-space-default">Welcome back!</p>
                        <p className="text-subHeadingTwo bg-heading-gradient-3 bg-clip-text text-transparent">Sign in to SuperPen</p>
                        <div className="mt-8">
                            <GoogleAuthButton source='login' />
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex items-center gap-2 my-4">
                                <div className="border-b flex-1 border-gray-4" />
                                <p className="text-bodyTwo text-gray-4">or use email</p>
                                <div className="border-b flex-1 border-gray-4" />
                            </div>
                            <div className="mt-2.5">
                                <p className="text-bodyTwo text-space-default mb-1">Email</p>
                                <InputField
                                    name="email"
                                    type='email'
                                    control={control}
                                    placeholder="hello@example.com"
                                />
                            </div>
                            <div className="mt-2.5">
                                <p className="text-bodyTwo text-space-default mb-1">Password</p>
                                <PasswordInputField
                                    name="password"
                                    control={control}
                                    placeholder="4+ characters"
                                />
                            </div>
                            <div className="flex justify-end mt-2.5">
                                <Link className="text-bodyThree underline text-space-default" to={'/forget-password'} onClick={() => {
                                    trackEvent(MIXPANEL_EVENTS.FORGOT_PW_CLICKED)
                                }}>Forgot Password?</Link>
                            </div>
                            <div>
                                <button className="filled-btn px-8" disabled={isPending}>Log In</button>
                            </div>
                        </form>
                        <div className="flex gap-1 mt-4">
                            <p className="text-bodyTwo text-space-default">Don’t have an account?</p>
                            <Link to={'/register?utm_source=login'} className="text-bodyTwoBold text-star-default" onClick={trackSignupClick}>Sign up</Link>
                        </div>
                    </div>
                    <div className="h-full w-full absolute ">
                        <img src={StrokeBackgroundImage} alt="background" className="w-full" />
                    </div>
                </div>
            </div>
        )

    );
};

export default Login;

const MobileViewLine = () => {
    return (
        <svg width="100%" height="148" viewBox="0 0 360 148" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-33.2057 104.855L290.438 23.17C314.593 17.0733 340.029 26.2784 354.688 46.4221L446.338 172.363C470.321 205.318 460.124 251.823 424.553 271.721L17.2447 499.561" stroke="url(#paint0_linear_173_1959)" stroke-width="16" />
            <defs>
                <linearGradient id="paint0_linear_173_1959" x1="-436.062" y1="408.29" x2="97.6297" y2="769.277" gradientUnits="userSpaceOnUse">
                    <stop offset="0.077773" stopColor="#7145FD" />
                    <stop offset="0.524091" stopColor="#FFE5FD" />
                    <stop offset="1" stopColor="#FF8BF6" />
                </linearGradient>
            </defs>
        </svg>

    )
}
const MobileView = ({ handleSubmit, onSubmit, control, isPending }) => {
    return (
        <div className="bg-star-light4 border min-h-dvh">
            <div className="flex items-center flex-col justify-center text-center mt-5">
                <div className="py-2">
                    <MiniLogo />
                </div>
                <p className="text-headingThree text-space-default">Welcome back!</p>
                <p className="text-subHeadingThree bg-clip-text text-transparent bg-heading-gradient-3">Sign in to SuperPen</p>
                <div className="mt-10">
                    <GoogleAuthButton source='login' />
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="px-5">
                <div className="flex items-center gap-2 my-4">
                    <div className="border-b flex-1 border-gray-4" />
                    <p className="text-bodyTwo text-gray-4">or use email</p>
                    <div className="border-b flex-1 border-gray-4" />
                </div>
                <div className="mt-2.5">
                    <p className="text-bodyThree text-space-default mb-1">Email</p>
                    <InputField
                        name="email"
                        type='email'
                        control={control}
                        placeholder="hello@example.com"
                    />
                </div>
                <div className="mt-2.5">
                    <p className="text-bodyThree text-space-default mb-1">Password</p>
                    <PasswordInputField
                        name="password"
                        control={control}
                        placeholder="4+ characters"
                    />
                </div>
                <div className="flex justify-end mt-2.5">
                    <Link className="text-bodyThree underline text-space-default" to={'/forget-password'} onClick={() => {
                        trackEvent(MIXPANEL_EVENTS.FORGOT_PW_CLICKED)
                    }}>Forgot Password?</Link>
                </div>
                <div className="flex gap-1 mt-5">
                    <p className="text-bodyTwo text-space-default">Don’t have an account?</p>
                    <Link to={'/register?utm_source=login'} className="text-bodyTwoBold text-star-default" onClick={trackSignupClick}>Sign up</Link>
                </div>
                <div className="fixed z-30 px-5 py-4  bottom-0 left-0 w-full bg-star-light4 border border-star-light2" >
                    <button className="filled-btn small w-full" disabled={isPending}>Log in</button>
                </div>
            </form>

            <div className="fixed bottom-0 w-full right-0 ">
                <MobileViewLine />
            </div>
        </div>
    )
}