import moment from "moment";

export const likesOption = [
    {
        label: '100 - 200',
        value: {
            min: 100,
            max: 200
        },
        lock: true
    },
    {
        label: '201 - 500',
        value: {
            min: 201,
            max: 500
        },
        lock: true
    },
    {
        label: '501 - 1000',
        value: {
            min: 501,
            max: 1000
        },
        lock: true
    },
    {
        label: '1k+',
        value: {
            min: 1000,
            max: undefined
        },
        lock: true
    },
]

export const contentToneOption = [
    {
        label: 'Funny',
        value: 'funny',
    },
    {
        label: 'Insightful',
        value: 'insightful',
        lock: true

    },
    {
        label: 'Relatable',
        value: 'shareworthy',
        lock: true
    },
]

export const contentLengthOption = [
    {
        label: 'Short',
        value: {
            min: 0,
            max: 75 * 5
        }
    },
    {
        label: 'Medium',
        value: {
            min: 75 * 5,
            max: 200 * 5
        },
        lock: true
    },
    {
        label: 'Long',
        value: {
            min: 200 * 5,
        },
        lock: true
    },
]

export const contentTimeOption = [
    {
        label: 'Last 3 Months',
        value: {
            startDate: moment().subtract(3, 'months').toDate(),
            endDate: null,
        },
        lock: true
    },
    {
        label: '3 Months to 1 Year',
        value: {
            startDate: moment().subtract(12, 'months').toDate(),
            endDate: moment().subtract(3, 'months').toDate()
        },
        lock: true
    },
    {
        label: 'Over 1 Year Ago',
        value: {
            startDate: null,
            endDate: moment().subtract(1, 'year').toDate() // No end date, assuming open-ended
        },
        lock: true
    },
];


export const sortByOption = [
    {
        label: 'Latest first',
        value: 'publishedDate:desc',
    },
    {
        label: 'Oldest first',
        value: 'publishedDate:asc',
    },
    {
        label: 'Likes (high - low)',
        value: 'totalLikeCount:desc',
    },
    {
        label: 'Engagement (high - low)',
        value: 'commentsCount:desc',
    },
]



export const userObject = [
    {
        index: 0,
        sharesCount: 132,
        commentsCount: 685,
        totalLikeCount: 5200,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdcs",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg1.png"],
        publishedDate: "2023-11-18T10:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile1.png",
            fullName: "David Jones",
            isActive: true,
            followerCount: 1000,
            url: "https://example.com/user1",
        },
        url: "https://example.com/post1",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 1,
        sharesCount: 56,
        commentsCount: 602,
        totalLikeCount: 5100,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccsd",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg2.png"],
        publishedDate: "2023-11-19T12:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile2.png",
            fullName: "James Johnson",
            isActive: true,
            followerCount: 2500,
            url: "https://example.com/user2",
        },
        url: "https://example.com/post2",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 2,
        sharesCount: 32,
        commentsCount: 85,
        totalLikeCount: 2400,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscsdcd",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg3.png"],
        publishedDate: "2023-11-20T15:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile3.png",
            fullName: "David Jones",
            isActive: false,
            followerCount: 500,
            url: "https://example.com/user3",
        },
        url: "https://example.com/post3",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 3,
        sharesCount: 56,
        commentsCount: 273,
        totalLikeCount: 4100,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs56",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg4.png"],
        publishedDate: "2023-11-21T09:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile4.png",
            fullName: "Natalie Walker",
            isActive: true,
            followerCount: 8000,
            url: "https://example.com/user4",
        },
        url: "https://example.com/post4",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 4,
        sharesCount: 653,
        commentsCount: 181,
        totalLikeCount: 13600,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs567",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg5.png"],
        publishedDate: "2023-11-22T08:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile5.png",
            fullName: "Daniel Clark",
            isActive: true,
            followerCount: 1500,
            url: "https://example.com/user5",
        },
        url: "https://example.com/post5",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 5,
        sharesCount: 104,
        commentsCount: 762,
        totalLikeCount: 9100,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs568",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg6.png"],
        publishedDate: "2023-11-23T14:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile6.png",
            fullName: "Robert Brown",
            isActive: true,
            followerCount: 2000,
            url: "https://example.com/user6",
        },
        url: "https://example.com/post6",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 6,
        sharesCount: 194,
        commentsCount: 1300,
        totalLikeCount: 11200,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs510",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg7.png"],
        publishedDate: "2023-11-24T16:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile7.png",
            fullName: "Christoper Lee",
            isActive: true,
            followerCount: 700,
            url: "https://example.com/user7",
        },
        url: "https://example.com/post7",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 7,
        sharesCount: 125,
        commentsCount: 1100,
        totalLikeCount: 12800,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs511",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg8.png"],
        publishedDate: "2023-11-25T11:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile8.png",
            fullName: "William taylor",
            isActive: false,
            followerCount: 50000,
            url: "https://example.com/user8",
        },
        url: "https://example.com/post8",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 8,
        sharesCount: 83,
        commentsCount: 782,
        totalLikeCount: 8200,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs512",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg9.png"],
        publishedDate: "2023-11-26T18:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile9.png",
            fullName: "Rachel Robinson",
            isActive: true,
            followerCount: 4200,
            url: "https://example.com/user9",
        },
        url: "https://example.com/post9",
        postType: "photo",
        isFreeTrial: true,
    },
    {
        index: 9,
        sharesCount: 58,
        commentsCount: 429,
        totalLikeCount: 5100,
        articleComponent: null,
        pollComponent: null,
        thumbnailURL: null,
        postId: "672faddsdccscs514",
        imageURL: ["https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/bg10.png"],
        publishedDate: "2023-11-27T20:00:00.000Z",
        userId: {
            profilePhoto: "https://founder-branding.s3.ap-south-1.amazonaws.com/trialUsers/profile10.png",
            fullName: "Amanda Moore",
            isActive: false,
            followerCount: 1000,
            url: "https://example.com/user10",
        },
        url: "https://example.com/post10",
        postType: "photo",
        isFreeTrial: true,
    },
];