const ClockCountDown = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_970_3578)">
                <rect width="20" height="20" rx="10" fill="#3AC2CC" />
                <path d="M10 4.69397C11.0494 4.69397 12.0753 5.00516 12.9479 5.58819C13.8204 6.17123 14.5005 6.99991 14.9021 7.96946C15.3037 8.93901 15.4088 10.0059 15.2041 11.0351C14.9993 12.0644 14.494 13.0098 13.7519 13.7519C13.0099 14.494 12.0644 14.9993 11.0352 15.204C10.0059 15.4088 8.93902 15.3037 7.96948 14.9021C6.99993 14.5005 6.17124 13.8204 5.58821 12.9478C5.00518 12.0753 4.69398 11.0494 4.69398 9.99998C4.69555 8.59322 5.25507 7.24452 6.2498 6.24979C7.24454 5.25506 8.59324 4.69553 10 4.69397ZM9.41044 9.99998C9.41044 10.1563 9.47255 10.3063 9.58312 10.4169C9.69368 10.5274 9.84364 10.5895 10 10.5895H13.5373C13.6937 10.5895 13.8437 10.5274 13.9542 10.4169C14.0648 10.3063 14.1269 10.1563 14.1269 9.99998C14.1269 9.84362 14.0648 9.69367 13.9542 9.5831C13.8437 9.47254 13.6937 9.41043 13.5373 9.41043H10.5896V6.46264C10.5896 6.30628 10.5274 6.15632 10.4169 6.04576C10.3063 5.9352 10.1564 5.87308 10 5.87308C9.84364 5.87308 9.69368 5.9352 9.58312 6.04576C9.47255 6.15632 9.41044 6.30628 9.41044 6.46264V9.99998Z" fill="#F7F6FF" />
            </g>
            <defs>
                <clipPath id="clip0_970_3578">
                    <rect width="20" height="20" rx="10" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default ClockCountDown;