import { useSelector } from "react-redux";
import BadgeCheckFilled from "../asset/svg/BadgeCheckFilledIcon";
import SoloLogo from "../asset/svg/SoloLogo";

const features = [
    {
        "title": "Limited access to viral post library",
    },
    {
        title: "Search viral posts by keyword (10/month)",
    },
    {
        title: "Recreate posts with AI (2/month)",
    },
    {
        title: "Add profiles of creators you love (2)",
    },
    {
        title: "Limited access to viral templates",
    },
    {
        title: "Viral post format guide",
    },
    {
        title: "Bookmark favourite posts",
    },
];
const FreePlanCard = ({ tenure }) => {

    const authState = useSelector((state) => state.auth);
    const isFreeTrial = authState.subscription.isFreeTrial;
    const color = '#FF90F6';
    const background = '#FFF4FE';

    return (
        <div className={`relative border flex-1 flex flex-col  rounded-[20px] px-[26px] py-9 max-w-[385px]`} style={{ borderColor: color, background: background }}>
            {isFreeTrial && <div className="absolute -top-[15px] left-0 right-0  m-auto rounded-lg text-white w-[fit-content] bg-star-default px-3 py-1">
                Current Plan 👇
            </div>}
            {/* <div className="absolute -top-[16px] right-0 w-[214px] h-[207px]">
                <SoloLogo className={'w-full h-full'} />
            </div> */}
            <p className="text-headingThree " style={{ color: color }}>Free Plan</p>
            <p className="text-bodyTwo text-gray-6">For those just starting out 💪</p>
            {/* <p className="text-subHeadingTwo font-semibold text-gray-5 mt-8"> <del>{currencySymbol}{amountBeforeDiscount?.toLocaleString()}</del></p> */}
            <div className="flex items-center gap-2 mt-14">
                <p className="text-headingTwo text-space-default">₹0</p>
                <span className="text-bodyTwo text-gray-5">{tenure === 'month' ? '/month' : '/month for 12 months'}</span>
            </div>
            <div className="mt-5">
                <p className="text-bodyTwoBold " style={{ color: color }}>Get started with</p>
                <div className="flex mt-4 flex-col gap-2">
                    {
                        features?.map((item, index) => (
                            <div key={index} className="flex items-start gap-2">
                                <div className="mt-[1px]">
                                    <BadgeCheckFilled color={color} />
                                </div>
                                <p className="text-bodyOne text-gray-6">{item.title}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="flex flex-col pt-5 items-center justify-center mt-auto">
                <p className="text-bodyTwo text-gray-6 text-center mb-4"> Cancel anytime, don't get charged next month
                </p>
            </div>
        </div>
    )
}
export default FreePlanCard;