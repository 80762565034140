import { useEffect, useRef, useState } from "react";
import GradientSearchIcon from "../../asset/svg/GradientSearchIcon";
import SoloLogo from "../../asset/svg/SoloLogo";
import { Search, Xmark } from "iconoir-react";
import { useQuery } from "@tanstack/react-query";
import { fetchRelatedKeyword } from "../../services/post.service";

const SearchField = ({ value, onChange, onPressEnter, onClear, showDropDown = true, ...other }) => {
    const ref = useRef();
    const [hasFocus, setFocus] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState(value);
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        if (document.hasFocus() && ref.current.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (isTyping) {
                setDebouncedValue(value);
            }
        }, 300);

        return () => clearTimeout(handler);
    }, [value, isTyping]);

    const query = { searchText: debouncedValue };

    const { data } = useQuery({
        queryKey: ['relatedKeywords', query],
        queryFn: async ({ queryKey }) => {
            const response = await fetchRelatedKeyword(queryKey[1]);
            return response;
        },
        enabled: !!debouncedValue && isTyping && showDropDown, // Only run query if there's a search term
        staleTime: Infinity,
        retry: false
    });

    const handleSelectKeyword = (selectedValue) => {
        setIsTyping(false); // Prevent API call when user selects keyword
        onPressEnter(selectedValue);
    };

    return (
        <>
            <div className="h-[52px] relative">
                <div className="absolute z-20 w-full bg-white  rounded-lg"
                    style={{
                        border: hasFocus ? '1px solid transparent' : '1px solid #E0DDED',
                        backgroundImage: hasFocus ? 'linear-gradient(#FFF, #FFF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)' : undefined,
                        backgroundOrigin: 'border-box',
                        backgroundClip: 'padding-box, border-box',
                        outline: 'none'
                    }}>
                    <div className="flex h-full items-center px-3">
                        <div className="cursor-pointer" onClick={() => { handleSelectKeyword(value); }}>
                            <GradientSearchIcon />
                        </div>

                        <input className="h-full py-3 w-full bg-transparent border-none outline-none pl-4"
                            ref={ref}
                            value={value}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            onChange={(event) => { onChange(event.target.value); setIsTyping(true); }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleSelectKeyword(event.target.value);
                                }
                            }}
                            {...other}
                        />

                        {
                            value ? (
                                <div onClick={() => {
                                    onClear();
                                    onChange('');
                                    setIsTyping(false);
                                }} className="cursor-pointer">
                                    <Xmark width={20} height={20} color="#8B8699" />
                                </div>
                            ) : (
                                <div>
                                    <SoloLogo />
                                </div>
                            )
                        }
                    </div>

                    {
                        showDropDown && !!debouncedValue && isTyping && (
                            <div className="flex flex-col gap-2 px-2.5 pb-2.5">
                                <div className="h-[1px] w-full bg-gray-3  " />
                                {
                                    data?.data?.length === 0 && (
                                        <p className="text-bodyTwo text-gray-5 text-center">No keyword found</p>
                                    )
                                }
                                {
                                    data?.data?.map((item, key) => {
                                        // Check if searchText exists in the item and highlight it
                                        const regex = new RegExp(`(${value})`, 'gi'); // Case-insensitive match
                                        const highlightedText = item?.tag?.replace(regex, (match) => `<b>${match}</b>`);

                                        return (
                                            <div
                                                key={key}
                                                className="flex gap-6 items-center py-[2px] px-2 hover:bg-[#F7F5FD] rounded-lg text-gray-5 hover:text-star-light cursor-pointer"
                                                onClick={() => {
                                                    handleSelectKeyword(item.tag)
                                                }}>
                                                <Search width={16} height={16} />
                                                <p className="text-bodyTwo" dangerouslySetInnerHTML={{ __html: highlightedText }}></p>
                                                <div className="p-2 ml-auto text-subText text-star-light bg-star-light3 rounded-[6px]">{item.frequency} posts</div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}
export default SearchField;