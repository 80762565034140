import { useEffect, useState, useMemo } from "react";
import SoloLogo from "../../asset/svg/SoloLogo";
import PlanTimer from "../../asset/svg/planTimer.svg";
import SelectBox from "../../component/custom-form/SelectBox";
import CustomTable from "../../component/CustomTable";
import PlanCard from "../../component/PlanCard";
import SegmentedBtn from "../../component/SegmentedBtn";
import MainLayout from "../../layout/main";
import invoiceDummy from "../../_mock/invoice";
import { Download, Xmark } from "iconoir-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices, fetchPlans, paymentVerified, subscribePlan } from "../../services/payment.service";
import LoaderGif from "../../asset/gif/loader.gif";
import moment from "moment";
import CancelSubscription from "./CancelSubscription";
import { trackEvent, trackPageView } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import FreePlanCard from "../../component/FreePlanCard";
import { toast } from "react-toastify";
import { RAZORPAY_KEY } from "../../utils/config";
import { updateSubscription } from "../../redux/slice/auth.slice";
import PaymentSuccessModel from "../payment/PaymentSucessModel";
import CouponDialog from "../../component/CouponDialogBox";
import InputField from "../../component/custom-form/InputField";

const options = [
    {
        label: 'Funny',
        value: 'funny'
    },
    {
        label: 'Insightful',
        value: 'insightful'
    },
    {
        label: 'Share worthy',
        value: 'shareworthy'
    },
]

function getDayDifference(billingStart, billingEnd) {
    // Convert timestamps to JavaScript Date objects
    const startDate = new Date(billingStart * 1000);  // Convert from seconds to milliseconds
    const endDate = new Date(billingEnd * 1000);      // Convert from seconds to milliseconds

    // Calculate the difference in time (milliseconds) and convert it to days
    const timeDifference = endDate - startDate;
    const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference;
}

function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const columns = [
    {
        name: 'Invoice Date',
        selector: row => `${moment(row.createdAt).format("DD MMM'YY")}`,
    },
    {
        name: 'Tenure',
        selector: row => capitalizeFirstLetter(row.subscriptionId.billingPeriod),
    },
    {
        name: 'Amount',
        selector: row => `${row.currency} ${row.amount / 100}`,
    },
    {
        name: 'Action',
        selector: (row) => {
            return (
                <div>
                    <a href={row.invoiceUrl} target="_blank" className="tertiary-btn small" onClick={() => {
                        trackEvent(MIXPANEL_EVENTS.INVOICE_VIEWED)
                    }}>
                        <Download />
                        View Invoice
                    </a>
                </div>
            )
        },
    },
];

const Plan = () => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;
    const endDate = subscription.endDate;
    const now = moment(); // Current date
    const diffInDays = moment(endDate).diff(now, 'days');
    const isFreeTrial = subscription.isFreeTrial;
    const tabs = ['Yearly', 'Monthly'];
    const [filter, setFilter] = useState({});
    const [showCoupon, setShowCoupon] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);
    const [openCancelSubscription, setOpenCancelSubscription] = useState(false);
    const [validCoupon, setValidCoupon] = useState(false);
    const [plans, setPlans] = useState([])
    // const [currentPlan, setCurrentPlan] = useState(null);
    const dispatch = useDispatch();
    const [response, setResponse] = useState(null);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [couponName, setCouponName] = useState('')
    const [offerId, setOfferId] = useState({})
    const [updatedMonthlyPlan, setUpdatedMonthlyPlan] = useState([]);
    const [updatedYearlyPlan, setUpdatedYearlyPlan] = useState([]);

    const { data, isLoading } = useQuery({
        queryKey: ['plans'],
        queryFn: () => fetchPlans(),
        staleTime: Infinity,
    });

    const query = {
        id: subscription.subscriptionId
    }
    const { data: invoiceData, isLoading: invoiceIsLoading } = useQuery({
        queryKey: ['invoices', query],
        queryFn: ({ queryKey }) => fetchInvoices(queryKey[1]),
        staleTime: Infinity,
        retry: false,
        enabled: !isFreeTrial
    });

    // Extract monthly and yearly plans from fetched data
    const monthlyPlan = data?.data?.plans.filter((item) => item.billingPeriod === 'monthly')?.sort((a, b) => a.amount - b.amount) || [];
    const yearlyPlan = data?.data?.plans.filter((item) => item.billingPeriod === 'yearly')?.sort((a, b) => a.amount - b.amount) || [];

    useEffect(() => {
        if (subscription.billingPeriod === 'monthly') {
            setActiveIndex(1);
            // setCurrentPlan(monthlyPlan[0]);
        } else {
            setActiveIndex(0);
            // setCurrentPlan(yearlyPlan[0]);
        }
    }, [data]);

    const handleChange = (index) => {
        // if (index === 0 && monthlyPlan.length > 0) {
        //     setCurrentPlan(monthlyPlan[0]);
        // } else if (yearlyPlan.length > 0) {
        //     setCurrentPlan(yearlyPlan[0]);
        // }
        setActiveIndex(index);
    };

    useEffect(() => {
        trackPageView(MIXPANEL_EVENTS.SETTINGS_VIEWED, {
            "Page viewed": "Plan & Billing, Preferences"
        })
    }, [])

    const countDown = `${diffInDays + 1} Day${diffInDays + 1 === 1 ? '' : 's'} Left`;

    const { mutate, isPending } = useMutation({
        mutationFn: subscribePlan,
        onSuccess: (data) => {
            if (!data.data?.subscriptionId) {
                toast.success(data.message);
                return;
            }

            const options = {
                key: RAZORPAY_KEY,  // Replace with Razorpay Key ID
                subscription_id: data.data.subscriptionId,
                name: 'SuperPen',
                description: 'Subscription Payment',
                handler: async (response) => {
                    try {
                        const result = await paymentVerified(response);
                        setResponse(result.data)
                        dispatch(updateSubscription(result.data))
                        setOpenSuccess(true);
                    } catch (error) {
                        console.log(error);
                        toast.error('Payment was successful, but there was an error processing your request on the server. Please try again later.');
                    }
                },
                modal: {
                    ondismiss: () => {
                        console.log('Payment Model Closed');
                    },
                },
                theme: {
                    color: '#7145FD',
                },
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
        },
        onError: (data) => {
            toast.error(data.message || 'Server Error');
        }
    });

    const handlePayment = (planId) => {
        if (planId) {
            mutate({
                planId,
                name: offerId?.name,
                paymentMethod: offerId?.paymentMethod
            });
        }
    };
    const handleRemoveCoupon = () => {
        setValidCoupon(false)
        setPlans([])
        setOfferId({})
    }

    const currencySymbol = monthlyPlan[0]?.currency === 'INR' ? '₹' : '$';
    const currentAmount = response?.subscription?.amountPaid;
    const currentPlan = response?.subscription?.planName;

    useEffect(() => {
        if (validCoupon) {
            // Function to update plans with coupon data
            const updatePlansWithCoupon = (plans, coupon) => {

                return plans.map(plan => {
                    // Find the coupon plan matching the current plan's planId
                    const couponPlan = coupon?.plans?.find(couponPlan => couponPlan?.planId === plan._id);
                    if (couponPlan) {
                        // If a matching coupon plan is found, update the plan with coupon data
                        return {
                            ...plan,
                            primaryText: couponPlan.primaryText,
                            secondaryText: couponPlan.secondaryText,
                            amountAfterDiscount: couponPlan.amountAfterDiscount,
                        };
                    }

                    // If no match is found, return the original plan
                    return plan;
                });
            };

            // Update monthly and yearly plans
            const updatedMonthly = updatePlansWithCoupon(monthlyPlan, offerId);
            const updatedYearly = updatePlansWithCoupon(yearlyPlan, offerId);

            // Update state
            setUpdatedMonthlyPlan(updatedMonthly);
            setUpdatedYearlyPlan(updatedYearly);

            // Set active index based on the type of the coupon
            setActiveIndex(1)
        }
    }, [validCoupon]);

    const currentPlans = useMemo(() => {
        if (validCoupon) {
            // Choose updated plans based on activeIndex and offerId type
            return activeIndex ? updatedMonthlyPlan : updatedYearlyPlan;
        }
        // Fallback to default plans if no coupon is applied
        return activeIndex ? monthlyPlan : yearlyPlan;
    }, [validCoupon, activeIndex, monthlyPlan, yearlyPlan]);

    if (isLoading) {
        return (
            <MainLayout>
                <div className="w-full h-full flex items-center justify-center">
                    <img src={LoaderGif} width={200} alt='loading' />
                </div>
            </MainLayout>
        )
    }
    return (
        <>
            {console.log(invoiceData)}
            {
                openCancelSubscription && (
                    <CancelSubscription onClose={() => {
                        setOpenCancelSubscription(false);
                    }} />
                )
            }
            {openSuccess && (
                <PaymentSuccessModel
                    amount={`${currentPlan} plan`}
                // onClose={() => {
                //     setOpenSuccess(false);
                // }}
                />
            )}

            <MainLayout>
                {
                    showCoupon && (
                        <CouponDialog onClose={() => { setShowCoupon(false) }} setValidCoupon={setValidCoupon} setPlans={setPlans} setCouponName={setCouponName} currency={monthlyPlan[0]?.currency} setOfferId={setOfferId} />
                    )
                }
                <div className="rounded-2xl px-5 py-8 bg-star-light3">
                    <p className="text-subHeadingTwoBold text-space-default">Your Plan & Billing</p>
                    <p className="text-bodyTwo bg-clip-text text-transparent bg-heading-gradient-3">Manage your billing information, subscription and invoices</p>
                    {
                        isFreeTrial && diffInDays >= 0 && (
                            <div className="relative py-4 px-8 bg-gray-moon flex items-center justify-between rounded-2xl mt-7">
                                <div>
                                    <p>
                                        <span className="text-subHeadingOne text-star-default">{countDown} </span>
                                        <span className="text-subHeadingTwo text-gray-5">of your FREE Trial</span>
                                    </p>
                                    {/* <p className="text-bodyOne mt-2.5 text-gray-5">Your plan will be activated from <span className="text-subHeadingThreeBold ml-3 text-space-default">{moment(endDate).format("DD MMM'YY")}</span> </p> */}
                                </div>
                                <div className="absolute  right-0 bottom-0">
                                    <img src={PlanTimer} alt="plan-timer" />
                                </div>
                            </div>
                        )
                    }

                    <div
                        className={`flex mt-7  ${isFreeTrial ? 'justify-between' : 'justify-center'
                            }`}
                    >
                        <div>
                            <SegmentedBtn list={tabs} onChange={handleChange} activeIndex={activeIndex} />
                            {/* <p className="text-bodyTwo mt-2 text-gray-5"><b>Save 30%</b> with yearly billing</p> */}
                        </div>
                        {
                            validCoupon && isFreeTrial ? <>
                                <div className="">
                                    <div className="flex justify-center items-center text-bodyOne text-star-light">
                                        <div className="mr-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <g clip-path="url(#clip0_815_4063)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0.9375C4.5472 0.9375 0.9375 4.5472 0.9375 9C0.9375 13.4528 4.5472 17.0625 9 17.0625C13.4528 17.0625 17.0625 13.4528 17.0625 9C17.0625 4.5472 13.4528 0.9375 9 0.9375ZM5.64783 8.97725C5.42816 8.75758 5.07201 8.75758 4.85234 8.97725C4.63267 9.19692 4.63267 9.55308 4.85234 9.77275L7.10234 12.0227C7.32201 12.2424 7.67816 12.2424 7.89783 12.0227L13.1478 6.77275C13.3675 6.55308 13.3675 6.19692 13.1478 5.97725C12.9282 5.75758 12.572 5.75758 12.3523 5.97725L7.50008 10.8295L5.64783 8.97725Z" fill="#A489F3" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_815_4063">
                                                        <rect width="18" height="18" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        {couponName}
                                        <b className="text-bodyOneBold ml-1">
                                            Code applied successfully
                                        </b>
                                    </div>
                                    <div className="flex justify-end">
                                        <button className="text-btn-coupon" onClick={handleRemoveCoupon}>
                                            Remove coupon code
                                        </button>
                                    </div>
                                </div>

                            </> :
                                isFreeTrial &&
                                <>
                                    <div className=" flex justify-center cursor-pointer items-center text-bodyOne text-star-default" onClick={() => { setShowCoupon(true) }}>
                                        <svg className="text-star-default" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="#7145FD" color="#7145FD" stroke-linecap="round">
                                            <path d="M11.4126 10.7314C11.869 10.7314 12.239 11.1015 12.239 11.5579C12.239 12.0143 11.869 12.3843 11.4126 12.3843C10.9561 12.3843 10.5861 12.0143 10.5861 11.5579C10.5861 11.1015 10.9561 10.7314 11.4126 10.7314ZM12.5908 11.5579C12.5908 10.9072 12.0633 10.3797 11.4126 10.3797C10.7619 10.3797 10.2344 10.9072 10.2344 11.5579C10.2344 12.2086 10.7619 12.7361 11.4126 12.7361C12.0633 12.7361 12.5908 12.2086 12.5908 11.5579Z" fill="white" stroke="white" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M6.40073 5.72008C6.85716 5.72008 7.22718 6.0901 7.22718 6.54654C7.22718 7.00298 6.85716 7.373 6.40073 7.373C5.94429 7.373 5.57427 7.00298 5.57427 6.54654C5.57427 6.0901 5.94429 5.72008 6.40073 5.72008ZM7.57893 6.54654C7.57893 5.89584 7.05143 5.36833 6.40073 5.36833C5.75002 5.36833 5.22252 5.89584 5.22252 6.54654C5.22252 7.19724 5.75002 7.72475 6.40073 7.72475C7.05143 7.72475 7.57893 7.19724 7.57893 6.54654Z" fill="white" stroke="white" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.5393 5.41985C12.4706 5.35116 12.3592 5.35116 12.2905 5.41985L5.27422 12.4362C5.20553 12.5048 5.20553 12.6162 5.27422 12.6849C5.3429 12.7536 5.45426 12.7536 5.52294 12.6849L12.5393 5.66857C12.6079 5.59989 12.6079 5.48853 12.5393 5.41985Z" fill="white" stroke="white" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        Do you have a
                                        <div className="text-bodyOneBold ml-1">
                                            Coupon Code?
                                        </div>
                                    </div>

                                </>

                        }

                    </div>
                    <div className="mt-6 flex gap-5">
                        <FreePlanCard tenure={activeIndex ? 'month' : 'year'} />
                        {
                            currentPlans.map((item, index) => (
                                <PlanCard key={index} index={index} {...item} isActive={subscription.planId === item._id} handlePayment={handlePayment} isPending={isPending} />
                            ))
                        }

                    </div>
                    <div className="flex justify-between mt-7">
                        <div>
                            <p className="text-bodyOneBold text-gray-6">Subscription History</p>
                            <p className="text-bodyTwo text-gray-5">Download your previous subscription invoices</p>
                        </div>
                        <div>
                            {/* <SelectBox
                            value={filter}
                            label="Recent"
                            options={options}
                            onRemoveFilter={() => { setFilter({}) }}
                            onChange={(val) => { setFilter(val) }}
                        /> */}
                        </div>
                    </div>
                    <div className="mt-5">
                        <CustomTable
                            data={invoiceData?.data || []}
                            columns={columns}
                            totalRows={10}
                            handlePageChange={() => { }}
                            handlePerRowsChange={() => { }}
                        />
                    </div>
                    {
                        !isFreeTrial && (
                            <div className="flex justify-between items-center">
                                <div>
                                    <p className="text-bodyOneBold text-venus-default">Danger Zone</p>
                                    <p className="text-bodyTwo text-gray-5 mt-2">Request to cancel your plan subscription</p>
                                </div>
                                <div className="my-10">
                                    <button className="text-btn small white" disabled={subscription.status === 'cancelled'} onClick={() => {
                                        trackEvent(MIXPANEL_EVENTS.CANCEL_REQUEST);
                                        setOpenCancelSubscription(true);
                                    }}>
                                        <Xmark />
                                        Request Cancellation
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </MainLayout>
        </>
    )
}
export default Plan;