const AvatarIcon = ({ width = '20', height = '20', src, alt = 'profile_image' }) => {
    if (src) {
        return (
            <div className={`rounded-full overflow-hidden`} style={{
                width: `${width}px`,
                height: `${height}px`
            }}>
                <img src={src} alt={alt} className="w-full h-full object-cover" />
            </div>
        )
    }

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="10" cy="10" r="10" fill="#F6FCFF" />
            <mask
                id="mask0_18_711"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width={width}
                height={height}
            >
                <circle cx="10" cy="10" r="10" fill="#F6FCFF" />
            </mask>
            <g mask="url(#mask0_18_711)">
                <ellipse cx="10" cy="17.6" rx="7.6" ry="4.4" fill="#8CD5FF" />
            </g>
            <circle cx="10" cy="8.5" r="3.6" fill="#8CD5FF" />
        </svg>
    );
};

export default AvatarIcon;
