import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import AuthGuard from './guard/authGuard';
import LogoutRequiredGuard from './guard/logoutRequiredGuard';
import PaymentGuard from './guard/paymentGuard';
import ForgetPassword from './pages/auth/forgetPassword';
import Login from './pages/auth/login';
import LoginToken from './pages/auth/loginToken';
import Register from './pages/auth/register';
import ResetPassword from './pages/auth/resetPassword';
import Bookmark from './pages/bookmarks';
import BookmarkPosts from './pages/bookmarks/bookmarkPosts';
import FavouriteCreator from './pages/FavouriteCreator';
import Inspiration from './pages/inspiration';
import OnBoarding from './pages/onboarding';
import VerifyLinkedin from './pages/onboarding/verifyLinkedin';
import Plan from './pages/plan';
import { persistor, store } from './redux/store';
import { GOOGLE_CLIENT_KEY } from './utils/config';
import PaymentPending from './pages/payment/paymentPending';
import PaymentFailed from './pages/payment/paymentFailed';
import Template from './pages/template';
import TopKeywords from './pages/TopKeywords';

const queryClient = new QueryClient()

function App() {

  return (
    <div className="App">

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_KEY}>
            <QueryClientProvider client={queryClient}>
              <Router>
                <Routes>

                  {/* Auth Routes */}
                  <Route path="/login" element={<LogoutRequiredGuard><Login /></LogoutRequiredGuard>} />
                  <Route path="/loginToken" element={<LogoutRequiredGuard><LoginToken /></LogoutRequiredGuard>} />
                  <Route path="/register" element={<LogoutRequiredGuard><Register /></LogoutRequiredGuard>} />
                  <Route path="/onboarding/" element={<AuthGuard><OnBoarding /></AuthGuard>} />
                  <Route path="/onboarding/verifyLinkedin" element={<AuthGuard><VerifyLinkedin /></AuthGuard>} />

                  <Route path="/forget-password" element={<ForgetPassword />} />
                  <Route path="/reset-password/:userId/:token" element={<ResetPassword />} />

                  {/* User routes */}
                  <Route path="/" element={<PaymentGuard><Inspiration /></PaymentGuard>} />
                  <Route path="/plan" element={<PaymentGuard><Plan /></PaymentGuard>} />
                  <Route path="/payment-pending" element={<AuthGuard><PaymentPending /></AuthGuard>} />
                  <Route path="/payment-failed" element={<AuthGuard><PaymentFailed /></AuthGuard>} />
                  <Route path="/bookmarks" element={<PaymentGuard><Bookmark /></PaymentGuard>} />
                  <Route path="/templates" element={<PaymentGuard><Template /></PaymentGuard>} />
                  <Route path="bookmarks/saved-posts" element={<PaymentGuard><BookmarkPosts /></PaymentGuard>} />
                  {/* Fav Creator */}
                  <Route path="/fav-creator" element={<PaymentGuard><FavouriteCreator /></PaymentGuard>} />

                  <Route path="/top-keywords" element={<PaymentGuard><TopKeywords /></PaymentGuard>} />
                </Routes>
              </Router>
              <ToastContainer closeButton={false} autoClose={2500} hideProgressBar={true} className={'toast-style'} transition={Slide} limit={5} />
            </QueryClientProvider>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
