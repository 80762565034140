import { ArrowLeft, ArrowRight } from "iconoir-react";
import OnBoardingStep3 from "../../asset/image/onboardingStep3.png";
import InputField from "../../component/custom-form/InputField";

const GoalFrom = ({ type, value, onChange, handleNext, handleBack, enableOther, setEnableOther, otherText, setOtherText }) => {
    const founderOptions = ['Startups', 'Fundraising', 'Bootstrapping', 'Product', 'Mental Health', 'Sales', 'Personal Branding'];
    const studentOptions = ['Jobs', 'Internship', 'Exams', 'Career', 'Placements', 'Startup', 'Interview', 'Freelancing'];
    const creatorOptions = ['Content', 'Engagement', 'Fame', 'Personal Branding', 'Authenticity', 'Journey', 'Finance'];
    const professionalOptions = ['Productivity', 'Mental Health', 'Growth', 'Leadership', 'Jobs', 'Personal Branding', 'Work Life Balance'];
    const freeLancerOptions = ['Clients', 'Sales', 'Networking', 'Finance', 'Personal Branding', 'Side hustle', 'Skills'];
    const otherOptions = ['Mindset', 'Community', 'Career', 'Mental Health', 'Growth', 'Travel', 'Personal Branding'];

    const optionMap = {
        Founder: founderOptions,
        Student: studentOptions,
        Creator: creatorOptions,
        Marketeer: professionalOptions,
        Freelancer: freeLancerOptions,
        other: otherOptions
    }

    const options = optionMap[type] || optionMap.other;

    const maxLength = 60;

    const handleClick = (currentVal) => {
        const prevInterests = [...value];
        if (prevInterests.includes(currentVal)) {
            const filteredInterests = prevInterests.filter((item) => item !== currentVal);
            onChange('interest', filteredInterests)
        } else {
            onChange('interest', [...prevInterests, currentVal]);
        }
    }

    const handleOtherClick = () => {
        if (enableOther) {
            setOtherText('');
            setEnableOther(false);
        } else {
            setEnableOther(true);
        }
    }

    const handleChange = (event) => {
        setOtherText(event.target.value);
    }

    const handleNextClick = () => {
        const interests = [...value];
        const otherInterests = otherText ? otherText.split(',').map((item) => item.trim()) : [];
        handleNext({
            interest: [...interests, ...otherInterests]
        });
    }

    return (
        <div>
            <div className="absolute [zIndex:-1] h-full left-0 top-0">
                <img className="h-full w-full object-contain" src={OnBoardingStep3} alt="onboarding-step-3" />
            </div>
            <div className="flex items-center justify-center gap-2">
                <div className="rounded-2xl bg-star-light w-[52px] h-[5px]" />
                <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
            </div>
            <div className="flex mt-16 [min-height:337px]">
                <div className="flex-1"></div>
                <div className="flex-1 flex flex-col">
                    <p className="text-headingTwo bg-hover-gradient-1 bg-clip-text text-transparent">Let’s get to know you better!</p>
                    <p className="mt-1 text-subHeadingThree text-gray-6">I want to post about _________________</p>
                    <div className="flex flex-wrap items-center gap-2.5 mt-8">
                        {
                            options.map((option) => (
                                <button key={option} className={`outlined-btn ${value.includes(option) ? 'active' : ''}`} onClick={() => { handleClick(option) }}>
                                    {option}
                                </button>
                            ))
                        }

                        <button className={`outlined-btn ${enableOther ? 'active' : ''}`} onClick={handleOtherClick}>
                            Other
                        </button>
                    </div>
                    {
                        enableOther && (
                            <div className="mt-4 mb-1">
                                <InputField name='otherText' maxLength={maxLength} value={otherText} onChange={handleChange} />
                            </div>
                        )
                    }
                    <div className="mt-auto">
                        <p className="text-bodyTwo text-gray-5">
                            Select the topics you’d like to post about, or click ‘Other’ to add more (separate by commas). Choose as many as you want.
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center mt-20">
                <button className="text-btn z-0" onClick={handleBack}>
                    <ArrowLeft />
                    Back
                </button>
                <button className="icon-btn" disabled={!value} onClick={handleNextClick}>
                    <ArrowRight />
                </button>
            </div>
        </div>
    )
}
export default GoalFrom;