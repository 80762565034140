import { Link } from 'react-router-dom';
import Founder from '../asset/image/founder.png';
import DialogBox from './DialogBox';
import { useSelector } from 'react-redux';
import { Xmark } from 'iconoir-react';
import { useEffect } from 'react';
import { trackEvent } from '../utils/mixpanel';
import { MIXPANEL_EVENTS } from '../utils/mixpanel/eventType';

const ConsultationDialog = ({ onClose }) => {
    const auth = useSelector((state) => state.auth);
    const name = auth.fullName;
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);
    return (
        <DialogBox>
            <div className="relative p-8 w-[750px] bg-white rounded-[25px] overflow-hidden">
                {
                    onClose && (
                        <div className="flex justify-end w-full z-20 fixed top-[120px] right-14 " style={{ color: '#C3B0FB' }} >
                            <button className='icon-outline-btn small border-none' style={{ backgroundColor: '#C3B0FB', borderRadius: "60px" }} onClick={onClose}>
                                <Xmark />
                            </button>
                        </div>
                    )
                }

                <div className="bottom-0 right-0 absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="119" height="135" viewBox="0 0 119 135" fill="none">
                        <path d="M115.152 330.493L21.3231 207.9C-2.54505 176.715 1.68402 132.399 31.0196 106.291L144.913 4.92959" stroke="url(#paint0_linear_1379_9528)" stroke-width="11" />
                        <defs>
                            <linearGradient id="paint0_linear_1379_9528" x1="118.58" y1="46.3089" x2="-64.4528" y2="180.203" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#8773FF" />
                                <stop offset="0.505" stop-color="#FFC0FA" />
                                <stop offset="1" stop-color="#FBDEBC" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className='flex justify-content gap-6'>

                    <div>
                        <div className='flex w-max justify-content items-center gap-2 px-6 py-2 rounded-[60px] bg-star-light4'>
                            <div className='text-center  text-bodyTwoBold bg-heading-gradient-3 bg-clip-text text-transparent'>
                                EXCLUSIVE OFFER
                            </div>
                            <div className='text-center text-bodyTwoBold'>
                                🎁
                            </div>
                        </div>

                        <div className='text-subHeadingTwoBold bg-base-gradient bg-clip-text text-transparent mt-8'>
                            Subscribe to SuperPen now
                        </div>

                        <div className='text-body-one mt-8'>
                            and get access to a <b>FREE 30-min consultation with Kunwar Raj</b> and learn tried & tested hacks to grow on Linkedin!
                        </div>

                        <div className='text-body-one mt-8 text-gray-4'>
                            Let’s make you viral on <b className='text-sky-default'>LinkedIn 💪🏻</b>
                        </div>

                        <div className='text-body-one mt-8 w-max'>
                            <Link to={'/payment-pending'} className="filled-btn mt-6" onClick={() => {
                                trackEvent(MIXPANEL_EVENTS.UPGRADE_CLICKED, {
                                    'Upgrade clicked from': 'Consulation'
                                })
                            }}>Subscribe now</Link>
                        </div>
                    </div>
                    {/* profile */}
                    <div className='border flex flex-col items-center justify-center rounded-xl border-star-light2 w-[490px]'>
                        <div className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-full rounded-t-xl' height="100" viewBox="0  400 100" fill="none">
                                <g clip-path="url(#clip0_1450_4115)">
                                    <rect width="400" height="100" fill="#E2F1FF" />
                                    <circle cx="64.2007" cy="39.9216" r="53.2124" fill="#BEE0FF" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1450_4115">
                                        <rect width="400" height="100" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className='flex justify-center items-center relative -top-12 -mb-8'>
                            <img src={Founder} alt="founder" />
                        </div>

                        <div>
                            <div className='flex justify-content items-center gap-2 px-2.5 py-[2px] rounded-[60px] bg-star-light4 ' >
                                <div className='text-center text-bodyTwoBold bg-heading-gradient-3 bg-clip-text text-transparent'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.783 0.543701H1.21544C0.757808 0.543701 0.369141 0.905638 0.369141 1.35151V10.9623C0.369141 11.4087 0.624154 11.7707 1.08179 11.7707H10.6493C11.1075 11.7707 11.5961 11.4087 11.5961 10.9623V1.35151C11.5961 0.905638 11.2411 0.543701 10.783 0.543701Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.783 0.543701H1.21544C0.757808 0.543701 0.369141 0.905638 0.369141 1.35151V10.9623C0.369141 11.4087 0.624154 11.7707 1.08179 11.7707H10.6493C11.1075 11.7707 11.5961 11.4087 11.5961 10.9623V1.35151C11.5961 0.905638 11.2411 0.543701 10.783 0.543701ZM4.64609 4.82065H6.15745V5.59103H6.17403C6.40445 5.17563 7.08501 4.75382 7.9265 4.75382C9.54158 4.75382 9.99227 5.61135 9.99227 7.1997V10.1668H8.38841V7.49213C8.38841 6.78109 8.10453 6.15719 7.44053 6.15719C6.63433 6.15719 6.24994 6.70304 6.24994 7.59906V10.1668H4.64609V4.82065ZM1.973 10.1668H3.57685V4.82065H1.973V10.1668ZM3.77733 2.94948C3.77739 3.0811 3.75152 3.21144 3.7012 3.33306C3.65089 3.45468 3.5771 3.5652 3.48406 3.6583C3.39103 3.75141 3.28057 3.82527 3.15898 3.87568C3.0374 3.92608 2.90708 3.95205 2.77546 3.95208C2.64384 3.95212 2.5135 3.92622 2.39189 3.87588C2.27028 3.82554 2.15977 3.75174 2.06669 3.65868C1.9736 3.56563 1.89976 3.45515 1.84938 3.33356C1.79899 3.21196 1.77306 3.08164 1.77305 2.95002C1.77303 2.68427 1.87856 2.42939 2.06644 2.24144C2.25433 2.05349 2.50917 1.94787 2.77492 1.9478C3.04067 1.94773 3.29557 2.05322 3.48355 2.24106C3.67154 2.42891 3.77721 2.68373 3.77733 2.94948Z" fill="#0A66C2" />
                                        <path d="M0.369141 13.4186V10.7375H4.00589L0.369141 13.4186Z" fill="#0A66C2" />
                                    </svg>
                                </div>
                                <div className='text-center text-bodyTwoBold text-[9px]' style={{ color: '#0A66C2' }}>
                                    Top voice
                                </div>
                            </div>
                        </div>

                        <div className='text-center text-bodyOneBold mt-2 text-space-dark'>
                            Kunwar Raj
                        </div>


                        <div className='flex flex-col items-start'>

                            <div className='flex mt-1 justify-center items-center gap-2'>
                                <div className='body-three text-star-default'>
                                    60K
                                </div>
                                <div className='body-three text-gray-6 text-nowrap'>
                                    Followers on Linkedin
                                </div>
                            </div>
                            <div className='flex justify-center items-center gap-2'>
                                <div className='body-three text-star-default'>
                                    1M+
                                </div>
                                <div className='body-three text-gray-6'>
                                    Followers on Instagram
                                </div>
                            </div>
                            <div className='flex justify-center items-center gap-2'>
                                <div className='body-three text-star-default'>
                                    225
                                </div>
                                <div className='body-three text-gray-6'>
                                    viral posts created
                                </div>
                            </div>
                        </div>



                        <div className='text-subTextBold filled-btn  mt-2 text-sky-default mb-4' style={{ background: '#E8F4FF' }}>
                            <p><a href="https://www.linkedin.com/in/kunwarraj01/" target="_blank">View Linkedin Profie</a></p>
                        </div>

                    </div>

                </div>


                {/* different div */}

                <div className='text-body-one mt-8 text-gray-4'>
                    P.S - We will <b className='text-gray-4'>email you a Google link to set up the call</b> <br /> once your plan is active.
                </div>
            </div>
        </DialogBox>
    )
}
export default ConsultationDialog;