import { useMutation, useQuery } from "@tanstack/react-query";
import posts from "../../asset/svg/posts.svg"
import allPosts from "../../asset/svg/all-post.svg"
import MainLayout from "../../layout/main";
import { createBookmark, getCollections } from "../../services/bookmark.service";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Plus, Xmark } from "iconoir-react";
import DialogBox from "../../component/DialogBox";
import { z } from "zod";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "../../component/form/InputField";
import { useSelector } from "react-redux";



const BookmarkDialog = ({ handleBookmark, refetch }) => {

    const handleLabelCreation = () => {
        toast.success('Collection Created');
        refetch();
        handleBookmark()
    }

    const labelSchema = z.object({
        label: z.string()
            .max(25, "Label should not exceed 25 characters")
            .min(4, "Label must be at least 4 characters"),
    });

    const { mutate, isPending } = useMutation({
        mutationFn: createBookmark,
        onSuccess: () => handleLabelCreation(),
        onError: () => {
            setError("label", { type: 'validate', message: 'Invalid label' });
            toast.error('Something went wrong');
        },
    });


    const { handleSubmit, control, setError, watch } = useForm({
        resolver: zodResolver(labelSchema),
        defaultValues: { label: '' },
    });

    const onSubmit = (data) => {
        mutate({ label: data.label });
    };

    return (
        <DialogBox>
            <div className="fixed inset-0 flex items-center justify-center bg-custom-light backdrop-blur-sm bg-opacity-50 z-50">
                <div className="bg-gray-moon w-[530px] max-h-screen rounded-3xl shadow-lg px-8 pt-9 pb-5">
                    <div className="flex justify-between mb-4 items-center">
                        <p className="text-subHeadingTwoBold">Create a new collection</p>
                        <div className="text-btn small">
                            <Xmark onClick={() => handleBookmark()} className="cursor-pointer" />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mt-2.5">
                            <p className="text-bodyThree text-space-default mb-1">Name</p>
                            <InputField
                                name="label"
                                type="text"
                                control={control}
                                placeholder="Label"
                                maxLength={25}
                            />
                            <p className="text-end text-gray-6 text-subText mt-2">{25 - watch("label").length} Characters</p>
                        </div>
                        <div className="pt-6 flex justify-between">
                            <button
                                className="text-btn small"
                                type="button"
                                onClick={() => handleBookmark()}
                            >
                                <ArrowLeft /> Back
                            </button>
                            <button className="filled-btn small text-base" type="submit">
                                Create new collection
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </DialogBox>
    );
};

const Bookmark = () => {

    const [collectionId, setCollectionId] = useState('');
    const navigate = useNavigate();

    const [bookmarkModal, setbookmarkModal] = useState(false)
    const handleBookmark = () => {
        setbookmarkModal(bookmarkModal ? false : true)
    }

    const { data: collectionList = [], isLoading, refetch } = useQuery({
        queryKey: ['bookmark-collections'],
        queryFn: () => getCollections(),
    });
    const authState = useSelector((state) => state.auth);
    const isFreeTrial = authState.subscription.isFreeTrial;

    return (
        <MainLayout>
            <div className="rounded-2xl  relative bg-star-light3" style={{
                marginTop: isFreeTrial ? 13 : 0
            }}>
                <div className="py-14 px-4 min-h-screen">
                    <div className='py-3 mb-7 flex justify-between items-center'>
                        <div>
                            <span className='text-headingThree'>Bookmarks</span>
                            <span className='text-subHeadingTwo text-gray-6'> | {collectionList?.AllPostData?.totalPostCount} Posts</span>
                        </div>
                        <div>
                            <button className="filled-btn small flex text-base" onClick={() => handleBookmark(!bookmarkModal)}>
                                <Plus />
                                <p>Create new collection</p>
                            </button>
                        </div>
                    </div>

                    <div className="flex gap-x-8 flex-wrap">
                        <div className="group cursor-pointer mb-8 hover:text-star-default" onClick={() => {
                            navigate(`/bookmarks/saved-posts`, { state: { collectionId: "all-posts", name: "All-Posts", count: collectionList?.AllPostData?.totalPostCount || 0 } });
                        }}>
                            <div className="w-[268px] h-[163px] flex justify-center items-center bg-[#DDDBFC] rounded-xl group-hover:bg-gray-moon">
                                <img src={allPosts} width={116} height={114} alt="img" />
                            </div>
                            <div className="mt-4">
                                <p className="text-subHeadingThreeBold">All Posts</p>
                                <span className="text-bodyThree text-[#5E5A73] group-hover:text-star-default">
                                    {collectionList?.AllPostData?.totalPostCount || 0} Posts {collectionList?.AllPostData?.latestUpdatedAt ? `• ${moment(collectionList?.AllPostData?.latestUpdatedAt).fromNow()}` : ''}
                                </span>
                            </div>
                        </div>
                        {!isLoading ? (
                            collectionList.data.map((obj) => (
                                <div className="group cursor-pointer hover:text-star-default" onClick={() => {
                                    navigate(`/bookmarks/saved-posts`, { state: { collectionId: obj._id, name: obj.label, count: obj.count } });
                                }}>
                                    <div className="w-[268px] h-[163px] flex justify-center items-center bg-[#DDDBFC] rounded-xl group-hover:bg-gray-moon">
                                        <img src={posts} width={105} height={105} alt="img" />
                                    </div>
                                    <div className="mt-4">
                                        <p className="text-subHeadingThreeBold">{obj.label}</p>
                                        <span className="text-bodyThree text-[#5E5A73] group-hover:text-star-default">{obj.count || 0} Posts • {moment(obj.updatedAt).fromNow()}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // <p>Loading...</p>
                            <></>
                        )}
                    </div>
                </div>
                {
                    bookmarkModal ?
                        <BookmarkDialog handleBookmark={handleBookmark} refetch={refetch} />
                        : <></>
                }

            </div>
        </MainLayout>
    )
}

export default Bookmark;