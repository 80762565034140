import { useMutation } from "@tanstack/react-query";
import { Mail, Xmark } from "iconoir-react";
import { useState } from "react";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { registerService, sendOTPService, verifyOTP } from "../../services/auth.service";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/slice/auth.slice";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import { identifyUser, setSuperProperties, trackEvent } from "../../utils/mixpanel";
import useResponsive from "../../hooks/useResponsive";
import MiniLogo from "../../asset/svg/MiniLogo";


const trackSuccessRegister = (mode, data) => {
    const { user, subscription } = data;
    identifyUser(user._id, {
        '$name': user.name,
        '$email': user.email,
        "Signup source": "email",
        "Member since": new Date()
    })
    setSuperProperties({
        // member_since: user?.createdAt,
        current_plan: subscription?.planName,
        version: "v2.0.2"
        // plan_renewal: subscription?.endDate,
        // persona: user?.occupation,
    })
    trackEvent(MIXPANEL_EVENTS.SIGNUP_SUCCESS, {
        mode,
    })
}

const VerifyOTP = ({ email, password, name }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { isMobile } = useResponsive();

    const { mutate: mutateRegister, isPending: isPendingRegister } = useMutation({
        mutationFn: registerService,
        onSuccess: (data) => {
            try {
                dispatch(login(data));
            } catch (dispatchError) {
                throw new Error('Sign-up was successful, but we encountered an issue saving your login information. Please log in again.');
            }
            trackSuccessRegister('email', data);
            navigate('/');
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong !!!');
            trackEvent(MIXPANEL_EVENTS.SIGNUP_FAILURE, {
                "Reason (sign-up failure)": error?.message || 'Server Error',
                email,
                name,
                mode: "email",
                // errorLog: JSON.stringify(error)
            })
        }
    });

    const { mutate: mututeSendOTP } = useMutation({
        mutationFn: sendOTPService,
        onError: (error) => {
            toast.error(error?.message || 'Failed to Resend OTP')
        }
    });

    const { mutate: mutateVerifyOTP, isPending: isPendingVerifyOTP } = useMutation({
        mutationFn: verifyOTP,
        onSuccess: () => {
            mutateRegister({ email, password, name })
            trackEvent(MIXPANEL_EVENTS.OTP_SUBMITTED, {
                "OTP verification success": true
            })
        },
        onError: (error) => {
            setErrorMessage(error?.message || 'Wrong verification code')
            trackEvent(MIXPANEL_EVENTS.SIGNUP_FAILURE, {
                "Reason (sign-up failure)": error?.message || 'Wrong verification code'
            })
            trackEvent(MIXPANEL_EVENTS.OTP_SUBMITTED, {
                "OTP verification success": false
            })
        }
    });

    const handleResendOTP = () => {
        mututeSendOTP({ email, name });
        trackEvent(MIXPANEL_EVENTS.OTP_RE_REQUESTED);
        toast.success('OTP Resend!')
    }

    const handleVerifyOTP = () => {
        if (otp.length < 4) {
            setErrorMessage('OTP is Required')
        } else {
            mutateVerifyOTP({ email, otp })
        }
    }
    return (
        isMobile ? (
            <MobileView
                email={email}
                otp={otp}
                setOtp={setOtp}
                errorMessage={errorMessage}
                handleResendOTP={handleResendOTP}
                handleVerifyOTP={handleVerifyOTP}
                isPendingRegister={isPendingRegister}
                isPendingVerifyOTP={isPendingVerifyOTP}

            />
        ) : (
            <div className="mt-6">
                <p className="text-headingTwo text-space-default">Verify your email</p>
                <p className="text-subHeadingThree my-2 bg-clip-text text-transparent bg-heading-gradient-3">We sent a verification code to</p>
                <div className="flex items-center gap-2 py-2.5">
                    <Mail width={24} height={24} color="#120B2A" />
                    <p className="text-subHeadingTwo text-space-default">{email}</p>
                </div>
                <div className="my-7">
                    <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span className="w-3"></span>}
                        renderInput={(props, index) => (
                            <input
                                {...props}
                                style={{
                                    ...props.style,
                                    width: '64px',
                                    height: '56px',
                                    borderRadius: '16px',
                                    fontSize: '20px',
                                    fontWeight: 700,
                                    color: '#7145FD',
                                    border: otp[index] ? '0.75px solid  #7145FD' : '0.75px solid  #8B8699',
                                    ...(errorMessage && {
                                        color: '#FD6483',
                                        border: '0.75px solid  #FD6483'
                                    })
                                }}
                            />
                        )}
                    />
                    {
                        errorMessage && (
                            <div className="flex items-center gap-2.5 mt-4">
                                <Xmark width={24} height={24} color="#FD6483" />
                                <p className="text-bodyThree text-venus-default">{errorMessage}</p>
                            </div>
                        )
                    }
                </div>
                <div>
                    <p className="text-bodyTwo text-space-default">Didn’t receive the email? <span onClick={handleResendOTP} className="text-bodyTwoBold text-star-light underline cursor-pointer">Resend</span></p>
                </div>
                <button className="filled-btn mt-4" aria-label="Verify Email" disabled={isPendingVerifyOTP || isPendingRegister} onClick={handleVerifyOTP}>Verify Email</button>
            </div>
        )
    )
}
export default VerifyOTP;


const MobileView = ({
    email,
    otp,
    setOtp,
    errorMessage,
    handleResendOTP,
    isPendingRegister,
    isPendingVerifyOTP,
    handleVerifyOTP
}) => {

    return (
        <div className="bg-star-light4 p-5 border min-h-dvh flex items-center flex-col text-center pt-5">
            <div className="py-2">
                <MiniLogo />
            </div>
            <p className="text-headingThree text-space-default">Verify your email</p>
            <p className="text-bodyOne my-2 bg-clip-text text-transparent bg-heading-gradient-3">We sent a verification code to</p>
            <div className="flex items-center gap-2 py-6">
                <Mail width={24} height={24} color="#120B2A" />
                <p className="text-subHeadingTwo text-space-default">{email}</p>
            </div>
            <div >
                <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span className="w-3"></span>}
                    renderInput={(props, index) => (
                        <input
                            {...props}
                            style={{
                                ...props.style,
                                width: '59px',
                                height: '56px',
                                borderRadius: '16px',
                                fontSize: '20px',
                                fontWeight: 700,
                                color: '#7145FD',
                                border: otp[index] ? '0.75px solid  #7145FD' : '0.75px solid  #8B8699',
                                ...(errorMessage && {
                                    color: '#FD6483',
                                    border: '0.75px solid  #FD6483'
                                })
                            }}
                        />
                    )}
                />
                {
                    errorMessage && (
                        <div className="flex items-center gap-2.5 mt-4">
                            <Xmark width={24} height={24} color="#FD6483" />
                            <p className="text-bodyThree text-venus-default">{errorMessage}</p>
                        </div>
                    )
                }
            </div>
            <div className="mt-6">
                <p className="text-bodyTwo text-space-default">Didn’t receive the email? <span onClick={handleResendOTP} className="text-bodyTwoBold text-star-light underline cursor-pointer">Resend</span></p>
            </div>
            <div className="fixed z-20 bottom-0 left-0 w-full border border-star-light2 bg-star-light4 py-4 px-5">
                <button className="filled-btn small w-full" aria-label="Verify Email" disabled={isPendingVerifyOTP || isPendingRegister} onClick={handleVerifyOTP}>Verify Email</button>
            </div>
            <div className="fixed bottom-0 right-0 w-full">
                <MobileLineSVG />
            </div>
        </div>
    )
}

const MobileLineSVG = () => {
    return (
        <svg width="360" height="182" viewBox="0 0 360 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6723 456.422L-38.066 168.295C-53.4112 90.37 17.7742 23.0928 94.7099 42.8092L292.258 93.4353C338.142 105.194 358.248 159.089 331.277 198.027L78.479 562.988" stroke="url(#paint0_linear_261_1176)" stroke-width="16" />
            <defs>
                <linearGradient id="paint0_linear_261_1176" x1="356.45" y1="152.109" x2="14.0248" y2="-46.2316" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#DCD1FF" />
                    <stop offset="0.341459" stopColor="#FFE5FD" />
                    <stop offset="1" stopColor="#F7AFF1" />
                </linearGradient>
            </defs>
        </svg>
    )
}
